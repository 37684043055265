import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";

import { updateAmountSelected } from "../../redux/actions/onlineBankomatActions";

import { Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";

import stoKuna from "./../../img/kuna-100-1a.jpg";
import dvjestoKuna from "./../../img/kuna-200-1a.jpg";
import petstoKuna from "./../../img/kuna-500-1a.jpg";
import tisucuKuna from "./../../img/kuna-1000-1a.jpg";

const mapStateToProps = state => ({
  onlineBankomat: state.onlineBankomat
});

const mapDispatchToProps = dispatch => ({
  updateAmountSelected: amount => dispatch(updateAmountSelected(amount))
});
class AmountSelector extends Component {
  _selectDifferentAmount = amount => () => {
    this.props.updateAmountSelected(amount);
  };

  render() {
    const atmSelected = get(this.props, "onlineBankomat.atmSelected");
    const amountSelected = get(this.props, "onlineBankomat.amountSelected");
    const amountEnabled = get(
      this.props,
      `onlineBankomat.amountEnabled[${atmSelected}]`,
      []
    );

    const cardClassNameFunction = amount => {
      if (amountEnabled.includes(amount)) {
        if (amountSelected === amount) {
          return "selected";
        } else {
          return "not-selected";
        }
      }
      return "disabled";
    };

    return (
      <div className="row amountSelector">
        <Col>
          <ul>
            <li>
              <Card
                className={cardClassNameFunction("100")}
                onClick={this._selectDifferentAmount("100")}
                style={{ cursor: "pointer", width: "15rem" }}
              >
                <CardImg top width="100%" src={stoKuna} alt="100-kn" />
                <CardBody>
                  <CardTitle>100 kn</CardTitle>
                </CardBody>
              </Card>
            </li>
            <li>
              <Card
                className={cardClassNameFunction("200")}
                onClick={this._selectDifferentAmount("200")}
                style={{ cursor: "pointer", width: "15rem" }}
              >
                <CardImg top width="100%" src={dvjestoKuna} alt="200-kn" />
                <CardBody>
                  <CardTitle>200 kn</CardTitle>
                </CardBody>
              </Card>
            </li>
            <li>
              <Card
                className={cardClassNameFunction("500")}
                onClick={this._selectDifferentAmount("500")}
                style={{ cursor: "pointer", width: "15rem" }}
              >
                <CardImg top width="100%" src={petstoKuna} alt="500-kn" />
                <CardBody>
                  <CardTitle>500 kn</CardTitle>
                </CardBody>
              </Card>
            </li>
            <li>
              <Card
                className={cardClassNameFunction("1000")}
                onClick={this._selectDifferentAmount("1000")}
                style={{ cursor: "pointer", width: "15rem" }}
              >
                <CardImg top width="100%" src={tisucuKuna} alt="1000-kn" />
                <CardBody>
                  <CardTitle>1000 kn</CardTitle>
                </CardBody>
              </Card>
            </li>
          </ul>
        </Col>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmountSelector);

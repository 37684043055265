import {
  ROUTE_TO_DASHBOARD_PAGE,
  ROUTE_TO_PLAN_PAGE,
  ROUTE_TO_LOGOUT_PAGE,
  ROUTE_TO_USERS_PAGE
} from "../../constants/routes";
import { ADMIN, DIREKTOR, KORISNIK, SUPER_ADMIN } from "../../constants/roles";

const adminNavigation = [
  {
    title: true,
    name: "Administracija"
  },
  {
    name: "Planovi prodaje",
    url: ROUTE_TO_DASHBOARD_PAGE,
    icon: "icon-doc"
  },
  {
    name: "Pregled podataka",
    url: ROUTE_TO_PLAN_PAGE,
    icon: "icon-docs"
  },
  {
    title: true,
    name: "Korisnici",
    wrapper: {
      // optional wrapper object
      element: "", // required valid HTML5 element tag
      attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    },
    class: "" // optional class names space delimited list for title item ex: "text-center"
  },
  {
    name: "Postavke korisnika",
    url: ROUTE_TO_USERS_PAGE,
    icon: "icon-settings"
  }
];

const directorNavigation = [
  {
    title: true,
    name: "Administracija"
  },
  {
    name: "Pregled podataka",
    url: ROUTE_TO_PLAN_PAGE,
    icon: "icon-docs"
  }
];

const userNavigation = [
  {
    title: true,
    name: "Administracija"
  },
  {
    name: "Pregled podataka",
    url: ROUTE_TO_PLAN_PAGE,
    icon: "icon-docs"
  }
];

const logout = {
  name: "Odjava",
  url: ROUTE_TO_LOGOUT_PAGE,
  icon: "icon-logout",
  class: "mt-auto",
  variant: "danger"
};

export const sidebarNavigation = userRole => {
  let navigation = {};

  switch (userRole) {
    case SUPER_ADMIN:
    case ADMIN:
      navigation = { items: [...adminNavigation, logout] };
      break;
    case DIREKTOR:
      navigation = { items: [...directorNavigation, logout] };
      break;
    case KORISNIK:
      navigation = { items: [...userNavigation, logout] };
      break;
    default:
      navigation = { items: [...userNavigation, logout] };
      break;
  }

  return navigation;
};

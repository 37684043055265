import { get } from "lodash-es";

const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validate = fieldObject => {
  let validation = { invalid: false, errors: [] };
  const rules = get(fieldObject, "validation", {});
  const value = get(fieldObject, "value", "");
  const dislayName = get(fieldObject, "displayName", "");

  if (rules.required && !value.toString().trim().length > 0) {
    validation.invalid = true;
    validation.errors.push(`Unesite ${dislayName}.`);
  }

  if (rules.maxLength && !value.toString().trim().length > rules.maxLength) {
    validation.invalid = true;
    validation.errors.push(
      `${dislayName} može imati maksimalnu dužinu od ${
        rules.maxLength
      } znakova.`
    );
  }

  if (rules.minLength && !value.toString().trim().length < rules.minLength) {
    validation.invalid = true;
    validation.errors.push(
      `${dislayName} može imati minimalnu dužinu od ${rules.minLength} znakova.`
    );
  }

  if (rules.matches && value !== rules.matches) {
    validation.invalid = true;
    validation.errors.push(`Unesene vrijednosti se ne podudaraju.`);
  }

  if (rules.email && !EMAIL_PATTERN.test(value)) {
    validation.invalid = true;
    validation.errors.push(`Unos za ${dislayName} nije valjan.`);
  }

  if (rules.enum && !rules.enum.includes(value)) {
    validation.invalid = true;
    validation.errors.push(`Unos za ${dislayName} nije valjan.`);
  }

  if (get(rules, "date.valid", false) && !value.isValid()) {
    validation.invalid = true;
    validation.errors.push(`Unos za ${dislayName} nije valjan.`);
  }

  if (
    get(rules, "date.maxValue", false) &&
    value.isAfter(rules.date.maxValue)
  ) {
    validation.invalid = true;
    validation.errors.push(`Unos za ${dislayName} nije valjan.`);
  }

  return validation;
};

export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const requestLogin = payload => ({
  type: REQUEST_LOGIN,
  payload: payload
});

export const AUTH_ATTEMPT_LOGIN = "AUTH_ATTEMPT_LOGIN";
export const authAttemptLogin = payload => ({
  type: AUTH_ATTEMPT_LOGIN,
  payload: payload
});

export const AUTH_TOKEN_LOADED = "AUTH_TOKEN_LOADED";
export const authTokenLoaded = payload => ({
  type: AUTH_TOKEN_LOADED,
  payload: payload
});

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload: payload
});

export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const loginFailure = payload => ({
  type: LOGIN_FAILURE,
  payload: payload
});

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const authLogout = () => ({
  type: AUTH_LOGOUT
});

export const AUTH_TOKEN_EXPIRED = "AUTH_TOKEN_EXPIRED";
export const authTokenExpired = payload => {
  return {
    type: AUTH_TOKEN_EXPIRED,
    payload: payload
  };
};

export const AUTH_TOKEN_REFRESH = "AUTH_TOKEN_REFRESH";
export const authTokenRefresh = payload => ({
  type: AUTH_TOKEN_REFRESH,
  payload: payload
});

export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const tokenRefreshSuccess = payload => ({
  type: TOKEN_REFRESH_SUCCESS,
  payload: payload
});

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
export const clearErrorMessage = payload => ({
  type: CLEAR_ERROR_MESSAGE,
  payload: payload
});

import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";

import { updatewayOfContactSelected } from "../../redux/actions/onlineBankomatActions";

import { Col, Card, CardImg, CardBody, CardText } from "reactstrap";

import emailImage from "./../../img/contact-by-mail.png";
import mobileImage from "./../../img/contact-by-sms.png";

const mapStateToProps = state => ({
  onlineBankomat: state.onlineBankomat
});

const mapDispatchToProps = dispatch => ({
  updatewayOfContactSelected: wayOfContact =>
    dispatch(updatewayOfContactSelected(wayOfContact))
});

class ContactSelector extends Component {
  _selectDifferentContact = wayOfContact => () => {
    this.props.updatewayOfContactSelected(wayOfContact);
  };

  render() {
    const wayOfContactAvailable = get(
      this.props,
      "onlineBankomat.wayOfContactAvailable"
    );
    const wayOfContactEnabled = get(
      this.props,
      "onlineBankomat.wayOfContactEnabled"
    );
    const wayOfContactSelected = get(
      this.props,
      "onlineBankomat.wayOfContactSelected"
    );

    const atmSelected = get(this.props, "onlineBankomat.atmSelected");
    const amountSelected = get(this.props, "onlineBankomat.amountSelected");
    const paymentSelected = get(this.props, "onlineBankomat.paymentSelected");

    const cardClassNameFunction = wayOfContact => {
      if (
        paymentSelected === "NONE" ||
        amountSelected === "NONE" ||
        atmSelected === "NONE"
      ) {
        return "disabled";
      }
      if (wayOfContactEnabled.includes(wayOfContact)) {
        if (wayOfContactSelected === wayOfContact) {
          return "selected";
        } else {
          return "not-selected";
        }
      }
      return "disabled";
    };

    return (
      <div className="row contactSelector">
        <Col>
          <ul>
            <li>
              <Card
                className={cardClassNameFunction("SMS")}
                onClick={this._selectDifferentContact("SMS")}
                style={{ cursor: "pointer", width: "166px" }}
              >
                <CardImg top width="100%" src={mobileImage} alt="SMS" />
                <CardBody>
                  <CardText>SMS</CardText>
                </CardBody>
              </Card>
            </li>
            <li>
              <Card
                className={cardClassNameFunction("EMAIL")}
                onClick={this._selectDifferentContact("EMAIL")}
                style={{ cursor: "pointer", width: "166px" }}
              >
                <CardImg top width="100%" src={emailImage} alt="EMAIL" />
                <CardBody>
                  <CardText>EMAIL</CardText>
                </CardBody>
              </Card>
            </li>
          </ul>
        </Col>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactSelector);

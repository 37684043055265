import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Row, Col } from "reactstrap";
import { Button } from "reactstrap";

import "./../css/defaultScreen.css";

class Maintenance extends Component {
  render() {
    return (
      <div className="main-area center-text">
        <div className="display-table">
          <div className="display-table-cell">
            <h1 className="title">
              <b>Upps...</b>
            </h1>
            <p className="desc font-white">
              Stranica je još u izradi, navratite nešto kasnije.
            </p>

            <Row>
              <Col>
                <br />
                <Link to="/">
                  <Button color="success" size="lg" style={{}}>
                    Vrati me na početak!
                  </Button>
                </Link>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Maintenance;

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Alert
} from "reactstrap";
import {
  authAttemptLogin,
  clearErrorMessage
} from "../../redux/actions/authActions";
import { get } from "lodash-es";
import AuthContainer from "../../containers/AuthContainer";
import { Spinner } from "../../containers/Spinner";

const mapStateToProps = state => {
  return {
    authentication: state.authentication
  };
};

const mapDispatchToProps = dispatch => {
  return {
    attemptLogin: authPayload => dispatch(authAttemptLogin(authPayload)),
    clearErrorMessage: payload => dispatch(clearErrorMessage(payload))
  };
};

class Login extends Component {
  state = {
    errorMessage: null
  };

  _runLogin = () => {
    const username = get(this.usernameTextInput, "value").toLowerCase();
    const password = get(this.passwordTextInput, "value");
    if (!username || !password) {
      this.setState({ errorMessage: "Unesite korisničko ime i lozinku." });
    } else {
      this.props.attemptLogin({ username, password });
    }
  };

  _handleKeyPress = e => {
    if (e.key === "Enter") {
      this._runLogin();
    }
  };

  clearErrorOnValueChange = () => {
    if (get(this.props, "authentication.errorMessage", null)) {
      this.props.clearErrorMessage({ errorMessage: null });
    }
    if (this.state.errorMessage) {
      this.setState({ errorMessage: null });
    }
  };

  render() {
    return (
      <AuthContainer>
        <p className="text-muted text-center">Prijava u korisnički račun</p>
        {(this.props.authentication.errorMessage ||
          this.state.errorMessage) && (
          <Alert color="danger" role="alert">
            {this.state.errorMessage || this.props.authentication.errorMessage}
          </Alert>
        )}
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-user" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            innerRef={input => {
              this.usernameTextInput = input;
            }}
            placeholder="Korisničko ime"
            onKeyPress={this._handleKeyPress}
            onChange={this.clearErrorOnValueChange}
          />
        </InputGroup>
        <InputGroup className="mb-4">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="password"
            innerRef={input => {
              this.passwordTextInput = input;
            }}
            placeholder="Lozinka"
            onKeyPress={this._handleKeyPress}
            onChange={this.clearErrorOnValueChange}
          />
        </InputGroup>
        <Col xs="6" sm="5" md="6" lg="5" xl="4" className="pl-0">
          <Button
            block
            disabled={get(this.props, "authentication.loginInProgress", false)}
            color="primary"
            className="px-4"
            onClick={this._runLogin}
          >
            {get(this.props, "authentication.loginInProgress", false) ? (
              <Spinner />
            ) : (
              "Prijava"
            )}
          </Button>
        </Col>
      </AuthContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

import {
  take,
  call,
  put,
  takeLatest,
  takeEvery,
  select
} from "redux-saga/effects";
import { push } from "connected-react-router";
import * as routes from "../../constants/routes";

import { client } from "../../config/apolloConfig";
import { CREATE_NEW_CONTRACT, REFRESH_CONTRACT, RESERVATION_FINISHED } from "../actions/onlineBankomatActions";
import { generalError } from "../actions/errorActions";
import CreateNewContractMutation from "../../operations/mutations/CreateNewContractMutation";
import GetContract from "../../operations/queries/GetContract";
import {
  getErrorObject,
  isMaintenanceInProgress
} from "../../constants/errorCodes";
import { get } from "lodash-es";

import { loadAllContractDataSuccess } from "../actions/onlineBankomatActions";

/**
 * Attempt to create new contract
 **/
function* createNewContractMutationWorker(action) {
  try {
    const onlineBankomatState = yield select(state => state.onlineBankomat);

    const createNewContractMutationResult = yield call(() =>
      client.mutate({
        mutation: CreateNewContractMutation,
        variables: {
          ATM: get(onlineBankomatState, "atmSelected"),
          amount: parseFloat(get(onlineBankomatState, "amountSelected")),
          paymentType: get(onlineBankomatState, "paymentSelected"),
          contactType: get(onlineBankomatState, "wayOfContactSelected"),
          language: get(onlineBankomatState, "languageSelected"),
          email: get(onlineBankomatState, "email"),
          phoneNumber: get(onlineBankomatState, "phoneNumber")
        }
      })
    );

    const createContract = get(
      createNewContractMutationResult,
      "data.createContract"
    );

    if (createContract) {
      const confirmationNumber = get(createContract, 'confirmationNumber');
      yield put(push(`${routes.ROUTE_TO_CONTRACT_PAGE}/${confirmationNumber}`));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    }
  }
}

/**
 * Attempt to refresh contract
 **/
function* refreshContractMutationWorker(action) {
  try {
    const onlineBankomatState = yield select(state => state.onlineBankomat);
    console.log('[REFRESH CONTRACT SAGA] Doing some refresh...');

    // Get the confirmation number from action payload
    const confirmationNumber = get(action, "payload.confirmationNumber");

    const getContractQueryResult = yield call(() =>
      client.query({
        query: GetContract,
        variables: {
          confirmationNumber,
        }
      })
    );

    console.log('[REFRESH CONTRACT SAGA] Query done... receiving data...');
    console.log(getContractQueryResult);
    const getContract = get(
      getContractQueryResult,
      "data.getContract"
    );

    if (getContract) {
      // const confirmationNumber = get(createContract, 'confirmationNumber');
      // yield put(push(`${routes.ROUTE_TO_CONTRACT_PAGE}/${confirmationNumber}`));
      yield put(loadAllContractDataSuccess(getContract));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    }
  }
}

export function* reservationFinishedWorker() {
  // yield createNewContractMutationWorker;
  // yield put(push(routes.ROUTE_TO_CONTRACT_PAGE));
}

/**
 * Activate some watchers
 **/
export function* createNewContractWatcher() {
  yield takeLatest(CREATE_NEW_CONTRACT, createNewContractMutationWorker);
}

export function* refreshContractWatcher() {
  yield takeLatest(REFRESH_CONTRACT, refreshContractMutationWorker);
}

export function* reservationFinishedWatcher() {
  yield takeLatest(RESERVATION_FINISHED, reservationFinishedWorker);
}

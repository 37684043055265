import { get } from "lodash-es";

export const SUPER_ADMIN = "superadmin";
export const ADMIN = "admin";
export const DIREKTOR = "director";
export const KORISNIK = "user";

export const userHasAtLeastOneRole = (userRoles, validRoles) => {
  return userRoles.some(element => {
    return validRoles.includes(element);
  });
};

export const isAdmin = user =>
  get(user, "role", "").includes(ADMIN) ||
  get(user, "role", "").includes(SUPER_ADMIN);
export const isDirektor = user => get(user, "role", "").includes(DIREKTOR);
export const isKorisnik = user => get(user, "role", "").includes(KORISNIK);

export const anyValidRole = user =>
  isAdmin(user) || isDirektor(user) || isKorisnik(user);

export const userHasRequiredRole = (user, role) =>
  get(user, "role", "").includes(role);

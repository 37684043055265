import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";
import { updateBankSelectorSelection } from "../../redux/actions/onlineBankomatActions";

import { Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";

import HPBimage from "./../../img/hpb.jpg";
import PBZimage from "./../../img/pbz.jpg";

const mapStateToProps = state => ({
  onlineBankomat: state.onlineBankomat
});

const mapDispatchToProps = dispatch => ({
  updateBankSelectorSelection: ATM => dispatch(updateBankSelectorSelection(ATM))
});

class BankSelector extends Component {
  _selectDifferentATM = ATM => () => {
    this.props.updateBankSelectorSelection(ATM);
  };

  render() {
    const atmSelected = get(this.props, "onlineBankomat.atmSelected");
    const atmEnabled = get(this.props, "onlineBankomat.atmEnabled", []);

    const cardClassNameFunction = ATM => {
      if (atmEnabled.includes(ATM)) {
        if (atmSelected === ATM) {
          return "selected";
        } else {
          return "not-selected";
        }
      }
      return "disabled";
    };

    return (
      <div className="row bankSelector">
        <Col>
          <ul>
            <li>
              <Card
                className={cardClassNameFunction("HPB")}
                onClick={this._selectDifferentATM("HPB")}
                style={{ cursor: "pointer", width: "10rem" }}
              >
                <CardImg top width="100%" src={HPBimage} alt="HPB" />
                <CardBody>
                  <CardTitle>HPB</CardTitle>
                </CardBody>
              </Card>
            </li>
            <li>
              <Card
                className={cardClassNameFunction("PBZ")}
                onClick={this._selectDifferentATM("PBZ")}
                style={{ cursor: "pointer", width: "10rem" }}
              >
                <CardImg top width="100%" src={PBZimage} alt="PBZ" />
                <CardBody>
                  <CardTitle>PBZ</CardTitle>
                </CardBody>
              </Card>
            </li>
          </ul>
        </Col>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankSelector);

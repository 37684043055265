import React, { Component } from "react";
import { get } from "lodash-es";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { NavLink as Link } from "react-router-dom";
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from "@coreui/react";
import defaultUser from "../../img/default-user.png";
import logo from "../../img/logo.jpg";
import logoMini from "../../img/logo-mini.jpg";
import {
  ROUTE_TO_DASHBOARD_PAGE,
  ROUTE_TO_USERS_PAGE,
  ROUTE_TO_PLAN_PAGE,
  ROUTE_TO_LOGOUT_PAGE
} from "../../constants/routes";
import { anyValidRole, isAdmin } from "../../constants/roles";

class Header extends Component {
  navLinks = [
    {
      name: "Planovi prodaje",
      url: ROUTE_TO_DASHBOARD_PAGE,
      access: isAdmin
    },
    {
      name: "Pregled podataka",
      url: ROUTE_TO_PLAN_PAGE,
      access: anyValidRole
    },
    {
      name: "Postavke korisnika",
      url: ROUTE_TO_USERS_PAGE,
      access: isAdmin
    }
  ];

  renderHeaderNavLinks = () =>
    this.navLinks.map(navLink =>
      navLink.access(this.props.user) ? (
        <NavItem className="px-3 " key={navLink.name}>
          <NavLink className="font-weight-bold" tag={Link} to={navLink.url}>
            {navLink.name.toUpperCase()}
          </NavLink>
        </NavItem>
      ) : null
    );

  render() {
    const firstName = get(this.props, "user.firstName", "");
    const lastName = get(this.props, "user.lastName", "");
    const displayHeading =
      firstName && lastName ? firstName + " " + lastName : "Postavke";
    const avatar = get(this.props, "user.avatar", defaultUser);

    return (
      <>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: "auto", height: 25, alt: "Logo" }}
          minimized={{ src: logoMini, width: "auto", height: 40, alt: "Logo" }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="d-md-down-none" navbar>
          {this.renderHeaderNavLinks()}
        </Nav>
        <Nav className="ml-auto" navbar>
          <span className="d-none d-md-block display-name">
            {displayHeading}
          </span>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={avatar} className="img-avatar" alt="avatar" />
            </DropdownToggle>
            <DropdownMenu right style={{ right: "auto" }}>
              <DropdownItem header tag="div" className="text-center">
                <strong>{displayHeading}</strong>
              </DropdownItem>
              <DropdownItem tag={Link} to={ROUTE_TO_LOGOUT_PAGE}>
                <i className="fa fa-lock" /> Odjava
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </>
    );
  }
}

export default Header;

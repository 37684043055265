import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "../views/Login";
import Logout from "../views/Logout";
import Dashboard from "../views/Dashboard";
import ErrorPage from "../views/Error";
import Users from "../views/Users";
import BusinessPlan from "../views/BusinessPlan";
import BusinessPlansPreview from "../views/BusinessPlansPreview";
import OfficeDataTable from "../views/OfficeDataTable";
import {
  ROUTE_TO_DASHBOARD_PAGE,
  ROUTE_TO_LOGIN_PAGE,
  ROUTE_TO_LOGOUT_PAGE,
  ROUTE_TO_USERS_PAGE,
  ROUTE_TO_ERROR_PAGE,
  ROUTE_TO_PLAN_PAGE,
  ROUTE_TO_PLAN_OFFICES,
  ROUTE_TO_OFFICE_DATA_TABLE
} from "../constants/routes";
import Layout from "../containers/menus/Layout";
import { isLoggedIn, requireAuth } from "../utils/auth";
import { isAdmin } from "../constants/roles";

import DefaultScreen from "../view/DefaultScreen";
import LandingPage from "../views/LandingPage/LandingPageIframe";

import OnlineBankomat from "../view/OnlineBankomat";
import Contract from "../view/Contract";
import ReservationInProcess from "../view/ReservationInProcess";
import Maintenance from "../view/Maintenance";

const Admin = Component => requireAuth(Component, isAdmin, ROUTE_TO_PLAN_PAGE);
//const Direktor = Component => requireAuth(Component, isDirektor, ROUTE_TO_PLAN_PAGE);
//const Korisnik = Component => requireAuth(Component, isKorisnik, ROUTE_TO_PLAN_PAGE);

// Custom component for wrapping of Protected Routes
// that redirect to login page if not logged in
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn() ? (
        props.location.pathname === ROUTE_TO_LOGOUT_PAGE ? (
          <Component {...props} />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      ) : (
        <Redirect to={{ pathname: ROUTE_TO_LOGIN_PAGE }} />
      )
    }
  />
);

// Custom component for wrapping of Authorization Routes
// that redirect to dashboard index if already logged in
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: ROUTE_TO_DASHBOARD_PAGE }} />
      )
    }
  />
);

class AppRouting extends Component {
  render() {
    return (
      <>
        <Switch>
          <Route exact path="/" component={DefaultScreen} />
          <Route exact path="/bankomat/" component={OnlineBankomat} />
          <Route exact path="/reservation/" component={ReservationInProcess} />
          <Route
            exact
            path="/contract/:confirmationNumber([0-9]{4}\-[a-zA-Z0-9]{8})"
            component={Contract}
          />
          <Route exact path="/*" component={Maintenance} />

          <AuthRoute exact path={ROUTE_TO_LOGIN_PAGE} component={Login} />
          <PrivateRoute
            exact
            path={ROUTE_TO_DASHBOARD_PAGE}
            component={Admin(Dashboard)}
          />
          <PrivateRoute
            exact
            path={ROUTE_TO_USERS_PAGE}
            component={Admin(Users)}
          />
          <PrivateRoute
            exact
            path={ROUTE_TO_PLAN_PAGE}
            component={BusinessPlansPreview}
          />
          <PrivateRoute
            exact
            path={ROUTE_TO_PLAN_OFFICES}
            component={BusinessPlan}
          />
          <PrivateRoute
            exact
            path={ROUTE_TO_OFFICE_DATA_TABLE}
            component={OfficeDataTable}
          />
          <PrivateRoute exact path={ROUTE_TO_LOGOUT_PAGE} component={Logout} />
          <Route exact path={ROUTE_TO_ERROR_PAGE} component={ErrorPage} />
          <Redirect to={{ pathname: ROUTE_TO_DASHBOARD_PAGE }} />
        </Switch>
      </>
    );
  }
}

export default AppRouting;

import React, { Component } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { ApolloProvider } from "react-apollo";
import { ToastContainer } from "react-toastify";

import AppRouting from "./config/RoutesConfig";
import { history } from "./config/historyConfig";
import { store } from "./config/storeConfig";
import { client } from "./config/apolloConfig";
import { authTokenLoaded } from "./redux/actions/authActions";
import { getLocalStorageToken } from "./constants/LocalStorageKeys";
import { isLoggedIn } from "./utils/auth";
import ScrollToTop from "./containers/ScrollToTop";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";
import "./css/design.css";
import "./css/designPart2.css";

class App extends Component {
  componentDidMount() {
    const token = getLocalStorageToken();
    if (token !== null && token !== undefined && isLoggedIn()) {
      store.dispatch(authTokenLoaded({ authToken: getLocalStorageToken() }));
    }
  }

  render() {
    return (
      <>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <ConnectedRouter history={history}>
              <ScrollToTop>
                <AppRouting />
              </ScrollToTop>
            </ConnectedRouter>
          </ApolloProvider>
        </Provider>
        <ToastContainer newestOnTop />
      </>
    );
  }
}

export default App;

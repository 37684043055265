import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { get } from "lodash-es";

class OfficeDataTable extends Component {
  planId = get(this.props, "match.params.id", "");
  officeId = get(this.props, "match.params.officeId", "");
  policyType = get(this.props, "match.params.policyType", "");

  render() {
    return (
      <Container fluid>
        <Row>
          <Col>{`${this.planId} > ${this.officeId} > ${this.policyType}`}</Col>
        </Row>
      </Container>
    );
  }
}

export default OfficeDataTable;

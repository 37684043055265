import { setContext } from "apollo-link-context";
import { ReduxCache } from "apollo-cache-redux";
import jwt_decode from "jwt-decode";
import { store } from "./storeConfig";

import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";

import { authTokenRefresh } from "../redux/actions/authActions";
import {
  getLocalStorageToken,
  removeLocalStorageToken,
  setLocalStorageToken
} from "../constants/LocalStorageKeys";

const link = createHttpLink({
  uri: process.env.REACT_APP_ENDPOINT_URI || "/endpoint"
});

const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists
  const token = getLocalStorageToken("authLink");

  // Check if token didn't expired.
  const sessionTimeout = 60 * 30; // 30 min
  if (typeof token !== "undefined" && token !== null) {
    let decoded;
    try {
      decoded = jwt_decode(token);
      // console.log('DECODED', decoded);
    } catch (e) {
      removeLocalStorageToken("authLink 1");
    }

    if (typeof decoded.iat === "number") {
      const currentMs = Math.floor(Date.now() / 1000);
      if (currentMs > decoded.iat + sessionTimeout) {
        removeLocalStorageToken("authLink 2");
      }
    }
  }
  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      rancom: Math.floor(Date.now() / 1000)
    }
  };
});

const getNewTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;

    if (headers) {
      const refreshToken = headers.get("newtoken");
      if (refreshToken) {
        // Refresh/Set new token
        setLocalStorageToken(refreshToken, "getNewTokenLink");
        store.dispatch(authTokenRefresh(refreshToken));
      }
    }

    return response;
  });
});

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache"
  },
  mutate: {
    fetchPolicy: "no-cache"
  }
};

export const cache = new ReduxCache({ store });
export const client = new ApolloClient({
  link: getNewTokenLink.concat(authLink.concat(link)), // link,
  cache,
  defaultOptions
});

import React, { Component } from "react";

class TimerCountdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateInFuture: this.props.date ? new Date(this.props.date) : Date.now(),
      seconds: 0,
      minutes: 0,
      hours: 0,
      inFuture: true,
    };
    this.tick = this.tick.bind(this);
  }

  tick() {
    const secondsRemaining = Math.floor(
      (this.state.dateInFuture - Date.now()) / 1000
    );

    const seconds = secondsRemaining % 60;
    const minutes = Math.floor((secondsRemaining / 60) % 60);
    const hours = Math.floor((secondsRemaining / 60 / 60) % 60);

    this.setState({
      seconds,
      minutes,
      hours,
      inFuture: secondsRemaining > 0,
    });
  }

  componentDidMount() {
    this.intervalHandle = setInterval(this.tick, 1000);
  }

  render() {

    let toRender;
    if (this.state.inFuture) {
      toRender = (<p style={{ width: "100%", fontSize: "20px", fontWeight: "bold" }}>{this.state.hours}:{this.state.minutes}:{this.state.seconds}</p>);
    }else{
      toRender = (<p style={{ width: "100%", fontSize: "20px", fontWeight: "bold" }}>EXPIRED</p>);
    }

    return (
      <div className="row amountSelector">
        {toRender}
      </div>
    );
  }
}

export default TimerCountdown;

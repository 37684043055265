import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { get } from "lodash-es";

class DeleteUserModal extends React.Component {
  submitDeleteUser = () => {
    const userId = get(this.props, "user.id", "");
    this.props.deleteUser(userId);
    this.props.toggle();
  };

  render() {
    const firstName = get(this.props, "user.firstName", "");
    const lastName = get(this.props, "user.lastName", "");

    return (
      <Modal
        backdrop="static"
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
      >
        <ModalHeader toggle={this.props.toggle}>
          Potvrda o brisanju korisnika
        </ModalHeader>
        <ModalBody>
          Jeste li sigurni da želite obrisati korisnika{" "}
          <span className="font-weight-bold">{firstName + " " + lastName}</span>
          ?
        </ModalBody>
        <ModalFooter>
          <Button className="hok-button" outline onClick={this.props.toggle}>
            Odustani
          </Button>{" "}
          <Button
            className="hok-button"
            color="primary"
            onClick={this.submitDeleteUser}
          >
            Obriši
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeleteUserModal;

import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  InputGroup,
  Button
} from "reactstrap";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import hrLocale from "moment/locale/hr";
import { validate } from "../../utils/validation";
import { Spinner } from "../../containers/Spinner";

moment.updateLocale("hr", hrLocale);

class CreateBusinessPlan extends Component {
  initialFormData = {
    name: {
      value: "",
      error: null,
      invalid: true,
      displayName: "ime plana",
      validation: {
        required: true
      }
    },
    comment: {
      value: "",
      error: null,
      invalid: true,
      displayName: "opis plana",
      validation: {
        required: true
      }
    },
    type: {
      value: "year",
      error: null,
      invalid: false,
      displayName: "tip plana",
      validation: {
        required: true,
        enum: ["year"]
      }
    },
    selectedDate: {
      value: moment(),
      error: null,
      invalid: false,
      displayName: "referentni datum",
      validation: {
        required: true,
        date: {
          valid: true,
          maxValue: moment()
        }
      }
    }
  };

  state = {
    focused: false,
    submitted: false,
    isFormValid: false,
    submissionErrorMessage: null,
    submissionInProgress: false,
    formData: { ...this.initialFormData }
  };

  submitCreateBusinessPlan = () => {
    if (!this.state.submissionInProgress) {
      let formDataObject = { ...this.state.formData };
      let isFormValid = true;
      for (let field in formDataObject) {
        const validation = validate(formDataObject[field]);
        formDataObject[field] = {
          ...formDataObject[field],
          invalid: validation.invalid,
          error: validation.errors[0]
        };
        isFormValid = isFormValid && !validation.invalid;
      }

      this.setState(
        { isFormValid, submitted: true, formData: formDataObject },
        () => {
          if (isFormValid) {
            this.setState(
              { submissionInProgress: true, submissionErrorMessage: null },
              () => {
                let payloadObject = {};
                for (let inputObject in this.state.formData) {
                  if (inputObject === "selectedDate") {
                    payloadObject[inputObject] = this.state.formData[
                      inputObject
                    ].value.toISOString();
                  } else {
                    payloadObject[inputObject] = this.state.formData[
                      inputObject
                    ].value.trim();
                  }
                }

                this.props.createBusinessPlan(payloadObject);
                this.setState({
                  submissionInProgress: false,
                  submitted: false,
                  formData: { ...this.initialFormData }
                });
              }
            );
          } else {
            this.setState({
              submissionErrorMessage:
                "Ispravite netočne unose pa pokušajte ponovno."
            });
          }
        }
      );
    }
  };

  inputHandler = (field, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: {
          ...this.state.formData[field],
          value: value
        }
      }
    });
  };

  formFeedback = () => {
    if (this.state.submitted && !this.state.isFormValid) {
      return (
        <FormGroup row>
          <Col xs="12">
            <div className="text-center form-feedback">
              {this.state.submissionErrorMessage}
            </div>
          </Col>
        </FormGroup>
      );
    }
  };

  render() {
    return (
      <Row className="first-section p-3 px-sm-5 py-sm-4 no-gutters">
        <Col sm={12} md={8} xl={5}>
          <FormGroup row>
            <Col>
              <Label for="imePlana">Ime plana</Label>
              <Input
                type="text"
                id="name"
                value={this.state.formData.name.value}
                invalid={
                  this.state.submitted && this.state.formData.name.invalid
                }
                onChange={event =>
                  this.inputHandler("name", event.target.value)
                }
              />
              <FormFeedback>{this.state.formData.name.error}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Label for="name">Opis</Label>
              <Input
                type="text"
                id="comment"
                value={this.state.formData.comment.value}
                invalid={
                  this.state.submitted && this.state.formData.comment.invalid
                }
                onChange={event =>
                  this.inputHandler("comment", event.target.value)
                }
              />
              <FormFeedback>{this.state.formData.comment.error}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col className="mb-3 mb-sm-0" xs={12} sm={6}>
              <Input
                type="select"
                id="type"
                disabled
                invalid={
                  this.state.submitted && this.state.formData.type.invalid
                }
              >
                <option value="year">Godišnji plan</option>
              </Input>
              <FormFeedback>{this.state.formData.type.error}</FormFeedback>
            </Col>
            <Col xs={12} sm={6}>
              <InputGroup>
                <Input
                  hidden
                  invalid={this.state.formData.selectedDate.invalid}
                />
                <SingleDatePicker
                  id="selectedDate"
                  date={this.state.formData.selectedDate.value}
                  onDateChange={date => this.inputHandler("selectedDate", date)}
                  readOnly
                  showDefaultInputIcon
                  firstDayOfWeek={1}
                  numberOfMonths={1}
                  small
                  focused={this.state.focused}
                  onFocusChange={({ focused }) => this.setState({ focused })}
                  displayFormat="DD-MM-YYYY"
                  placeholder="Datum"
                  isOutsideRange={date => date.isAfter(moment())}
                />
                <FormFeedback>
                  {this.state.formData.selectedDate.error}
                </FormFeedback>
              </InputGroup>
            </Col>
          </FormGroup>
          {this.formFeedback()}
        </Col>
        <Col md={{ offset: 2, size: 2 }} xl={{ offset: 5, size: 2 }}>
          <FormGroup row>
            <Col className="text-right">
              <Button
                disabled={this.props.creating}
                className="hok-button create-plan-button"
                onClick={this.submitCreateBusinessPlan}
              >
                {this.props.creating ? <Spinner /> : "Kreiraj"}
              </Button>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default CreateBusinessPlan;

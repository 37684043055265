import gql from "graphql-tag";

export const GetAllSinglePlanData = gql`
  query getAllSinglePlanData($businessPlanId: Int) {
    regions: getRegions(businessPlanId: $businessPlanId) {
      regionId
      regionName
    }

    mainOffices: getMainOffices(businessPlanId: $businessPlanId) {
      regionId
      regionName
      mainOfficeId
      mainOfficeName
    }

    offices: getOffices(businessPlanId: $businessPlanId) {
      regionId
      regionName
      mainOfficeId
      mainOfficeName
      officeId
      officeName
    }

    policies: getPolicies(businessPlanId: $businessPlanId) {
      policyType
    }
  }
`;

export default GetAllSinglePlanData;

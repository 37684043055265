import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";
import {
  loadBusinessPlans,
  createBusinessPlan
} from "../../redux/actions/businessActions";
import BusinessPlans from "./BusinessPlans";
import CreateBusinessPlan from "./CreateBusinessPlan";
import { isAdmin } from "../../constants/roles";
import { redirectToPlan } from "../../redux/actions/navigationActions";

const mapStateToProps = state => ({
  user: state.authentication.user,
  business: state.business
});

const mapDispatchToProps = dispatch => ({
  loadBusinessPlans: () => dispatch(loadBusinessPlans()),
  createBusinessPlan: businessPlan =>
    dispatch(createBusinessPlan(businessPlan)),
  redirectToPlan: planId => dispatch(redirectToPlan(planId))
});

class Dashboard extends Component {
  componentDidMount() {
    if (!get(this.props, "business.loadingInProgress", false)) {
      this.props.loadBusinessPlans();
    }
  }

  render() {
    return (
      <>
        {isAdmin(this.props.user) && (
          <CreateBusinessPlan
            creating={this.props.business.creatingBusinessPlan}
            createBusinessPlan={this.props.createBusinessPlan}
          />
        )}
        <BusinessPlans
          business={this.props.business}
          redirectToPlan={this.props.redirectToPlan}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Badge } from "reactstrap";
import ReactTable from "react-table";
import { get } from "lodash-es";
import {
  loadUsers,
  createUser,
  updateUser,
  deleteUser
} from "../../redux/actions/usersActions";
import defaultUser from "../../img/default-user.png";
import UserModal from "./UserModal";
import DeleteUserModal from "./DeleteUserModal";
import { Spinner } from "../../containers/Spinner";
import { SUPER_ADMIN, ADMIN, DIREKTOR, KORISNIK } from "../../constants/roles";

const mapStateToProps = state => ({
  users: state.users,
  isUserModalOpen: state.isUserModalOpen,
  isDeleteUserModalOpen: state.isDeleteUserModalOpen
});

const mapDispatchToProps = dispatch => ({
  loadUsers: () => dispatch(loadUsers()),
  createUser: user => dispatch(createUser(user)),
  updateUser: user => dispatch(updateUser(user)),
  deleteUser: id => dispatch(deleteUser({ id }))
});

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserModalOpen: false,
      isDeleteUserModalOpen: false
    };
  }

  user = {};

  toggleUserModal = () => {
    const newBoolState = !(this.state.isUserModalOpen === true);
    this.setState({ isUserModalOpen: newBoolState });
  };

  toggleDeleteUserModal = () => {
    const newBoolState = !(this.state.isDeleteUserModalOpen === true);
    this.setState({ isDeleteUserModalOpen: newBoolState });
  };

  AVATAR_COLUMN = {
    id: "avatar",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Slika",
    minWidth: 55,
    width: 55,
    sortable: false,
    resizable: false,
    Cell: rowInfo => {
      const avatar = get(rowInfo, "original.avatar", defaultUser);
      return (
        <div style={{ width: "35px", textAlign: "center" }}>
          <img
            style={{ verticalAlign: "middle", minWidth: "35px" }}
            src={avatar || "./img/avatar/default-user.png"}
            className="img-avatar"
            alt="avatar"
          />
        </div>
      );
    }
  };

  FULL_NAME_COLUMN = {
    id: "fullName",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Ime i prezime",
    minWidth: 250,
    sortable: true,
    accessor: d => d.firstName + " " + d.lastName,
    sortMethod: (a, b) => {
      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    },
    Cell: rowInfo => (
      <div>{rowInfo.original.firstName + " " + rowInfo.original.lastName}</div>
    )
  };

  EMAIL_COLUMN = {
    id: "email",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Email",
    minWidth: 250,
    sortable: true,
    accessor: d => d.email,
    sortMethod: (a, b) => {
      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    },
    Cell: rowInfo => <div>{rowInfo.original.email}</div>
  };

  ROLE_COLUMN = {
    id: "role",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Uloga",
    minWidth: 150,
    sortable: true,
    accessor: d => d.role,
    sortMethod: (a, b) => {
      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    },
    Cell: rowInfo => this.formatRoleCell(rowInfo.original.role)
  };

  STATUS_COLUMN = {
    id: "status",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Status",
    minWidth: 150,
    sortable: true,
    accessor: d => d.status,
    sortMethod: (a, b) => {
      if (a === b) {
        return 0;
      }
      return a > b ? 1 : -1;
    },
    Cell: rowInfo => this.formatStatusCell(rowInfo.original.accountLocked)
  };

  ACTIONS_COLUMN = {
    id: "actions",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Akcije",
    minWidth: 150,
    sortable: false,
    Cell: rowInfo => (
      <div>
        <i
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.user = { ...rowInfo.original };
            this.toggleUserModal();
          }}
          className="fa fa-edit fa-lg edit"
        />{" "}
        <i
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.user = { ...rowInfo.original };
            this.toggleDeleteUserModal();
          }}
          className="fa fa-trash fa-lg delete"
        />
      </div>
    )
  };

  formatRoleCell = role => {
    let text = "Nije definirana";
    let color = "white";
    if (role === SUPER_ADMIN) {
      text = "Super Admin";
      color = "purple";
    }
    if (role === ADMIN) {
      text = "Administrator";
      color = "danger";
    }
    if (role === DIREKTOR) {
      text = "Direktor";
      color = "warning";
    }
    if (role === KORISNIK) {
      text = "Korisnik";
      color = "secondary";
    }
    return (
      <h5
        style={{
          float: "left",
          marginRight: "5px",
          marginBottom: 0,
          lineHeight: "33px"
        }}
      >
        <Badge color={color}>{text.toUpperCase()}</Badge>
      </h5>
    );
  };

  formatStatusCell = accountLocked => {
    const color = accountLocked ? "inactive" : "success";
    const text = accountLocked ? "neaktivan" : "aktivan";
    return (
      <h5
        style={{
          float: "left",
          marginRight: "5px",
          marginBottom: 0,
          lineHeight: "33px"
        }}
      >
        <Badge color={color}>{text.toUpperCase()}</Badge>
      </h5>
    );
  };

  fetchColumns = () => {
    return [
      this.AVATAR_COLUMN,
      this.FULL_NAME_COLUMN,
      this.EMAIL_COLUMN,
      this.ROLE_COLUMN,
      this.STATUS_COLUMN,
      this.ACTIONS_COLUMN
    ];
  };

  componentDidMount() {
    if (!get(this.props, "users.loadingInProgress", false)) {
      this.props.loadUsers();
    }
  }

  render() {
    const userList = get(this.props, "users.userList", []);
    const creatingUserInProgress = get(
      this.props,
      "users.creatingUserInProgress",
      false
    );

    return (
      <Container fluid>
        <Row className="mb-3 no-gutters">
          <Col sm={{ offset: 8, size: 4 }} className="text-right">
            <Button
              disabled={creatingUserInProgress}
              onClick={() => {
                this.user = {};
                this.toggleUserModal();
              }}
              className="hok-button"
            >
              {creatingUserInProgress ? <Spinner /> : "Novi Korisnik"}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactTable
              id="users"
              ref="users"
              className="-striped -highlight"
              data={userList}
              columns={this.fetchColumns()}
              loading={this.props.users.loadingInProgress}
              resizable={false}
              pageSize={userList.length}
              multiSort={false}
              showPagination={false}
              loadingText="Učitavanje u tijeku..."
              noDataText="Nema podataka"
            />
          </Col>
        </Row>

        {this.state.isUserModalOpen && (
          <UserModal
            isOpen={this.state.isUserModalOpen}
            toggle={this.toggleUserModal}
            user={this.user}
            userModalAction={
              this.user.id ? this.props.updateUser : this.props.createUser
            }
          />
        )}
        {this.state.isDeleteUserModalOpen && (
          <DeleteUserModal
            isOpen={this.state.isDeleteUserModalOpen}
            toggle={this.toggleDeleteUserModal}
            user={this.user}
            deleteUser={this.props.deleteUser}
          />
        )}
      </Container>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);

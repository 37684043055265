import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ROUTE_TO_PLAN_PAGE } from "../constants/routes";
import {
  getLocalStorageToken,
  removeLocalStorageToken
} from "../constants/LocalStorageKeys";
import jwt_decode from "jwt-decode";
import { get } from "lodash-es";
import { Spinner } from "../containers/Spinner";

export const isLoggedIn = () => {
  const token = getLocalStorageToken("AUTH_TOKEN");
  if (typeof token !== "undefined" && token !== null) {
    try {
      jwt_decode(token);
      return true;
    } catch (e) {
      removeLocalStorageToken("AUTH_TOKEN");
      return false;
    }
  }
  return false;
};

const mapStateToProps = state => ({
  authentication: state.authentication
});

/**
 * Higher order component (HOC) to require auth
 *
 * usage:
 *   import requireAuth from './RequireAuth';
 *
 *   <Route component={requireAuth(Todos, checkRoles)} name="todos"/>
 *
 * @export
 * @param {any} WrappedComponent
 * @param {any} checkRoles
 * @param {string} redirectTo
 *            checkRoles:   function that accepts an object containing user.role
 *                          returns true or false whether user has permission to access the route
 *            redirectTo:   string that represents the route to redirect the user to if it doesn't
 *                          have access to the requested route
 * @returns WrappedComponent when authenticated, null when not authenticated
 *          A redirect should occur before nothing is rendered
 */
export function requireAuth(
  WrappedComponent,
  checkRoles = false,
  redirectTo = ROUTE_TO_PLAN_PAGE
) {
  class AuthenticatedComponent extends React.Component {
    render() {
      return this._userDataLoaded() ? (
        this._checkRoles() ? (
          <WrappedComponent {...this.props} />
        ) : (
          <Redirect to={{ pathname: redirectTo }} />
        )
      ) : (
        <Spinner className="spinner-positioning" fontSize="42px" />
      );
    }

    _userDataLoaded() {
      return !!get(this.props, "authentication.user.id", "");
    }

    _checkRoles() {
      if (typeof checkRoles !== "function") {
        return false;
      }
      return checkRoles(this.props.authentication.user);
    }
  }

  return connect(mapStateToProps)(AuthenticatedComponent);
}

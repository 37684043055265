import gql from "graphql-tag";

export const GetRegionsQuery = gql`
  query getRegions {
    getRegions: getRegions {
      regionId
      regionName
    }
  }
`;

import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";

import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Jumbotron, Button } from "reactstrap";

import "font-awesome/css/font-awesome.min.css";
import TimerCountdown from "./components/TimerCountdown";

import {
  refreshContract,
} from "../redux/actions/onlineBankomatActions";

const mapStateToProps = state => ({
  onlineBankomat: state.onlineBankomat
});

const mapDispatchToProps = dispatch => ({
  refreshContract: (confirmationNumber) => dispatch(refreshContract(confirmationNumber))
});

class Contract extends Component {
  confirmationNumber = this.props.match.params.confirmationNumber;

  componentDidMount() {
    this.interval = setInterval(() => {
      this.props.refreshContract(this.confirmationNumber);
    }, 15 * 1000);
    this.props.refreshContract(this.confirmationNumber);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const getContract = get(this.props.onlineBankomat, 'getContract');
    
    const confirmationNumber = get(getContract, 'confirmationNumber');
    const ATM = get(getContract, 'ATM');
    const amount = get(getContract, 'amount');
    const paymentType = get(getContract, 'paymentType');
    const contactType = get(getContract, 'contactType');
    const address = get(getContract, 'address');
    const paymentMetaAddress = get(getContract, 'paymentMetaAddress');
    const paymentMetaAmount = get(getContract, 'paymentMetaAmount');
    const validDate = get(getContract, 'validDate');

    const status = get(getContract, 'status');
    const comment = get(getContract, 'comment', '');

    if (typeof confirmationNumber === 'undefined') {
      return (
        <div
        className="App"
        style={{ textAlign: "center", WebkitFilter: "blur(0px) saturate(1)" }}
      >
        <Container>
          <Row style={{ paddingTop: "80px" }}>
            <Col>
            <i
              className="fa fa-spinner fa-spin"
              style={{ fontSize: "128px" }}
            />
            </Col>
          </Row>
        </Container>
      </div>
      );
    }

    return (
      <div
        className="App"
        style={{ textAlign: "center", WebkitFilter: "blur(0px) saturate(1)" }}
      >
        <Container>
          <Row style={{ paddingTop: "80px" }}>
            <Col />
            <Col sm="8">
              <Jumbotron>
                <Row>
                  <Col>
                    <h1>Vaš kod je rezerviran!</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3>Još samo jedan korak...</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5
                      style={{
                        padding: "0",
                        margin: "0",
                        color: "#333",
                        background: "#EEE"
                      }}
                    >
                      Kako bi dobili pristup kod-u
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5
                      style={{
                        padding: "0",
                        margin: "0",
                        color: "#333",
                        background: "#EEE"
                      }}
                    >
                      Potrebno je uplatiti{" "}
                      <strong>{(parseFloat(paymentMetaAmount)).toFixed(8)}</strong> {paymentType}{' '}
                      na adresu:
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5
                      style={{
                        padding: "0",
                        margin: "0",
                        color: "#333",
                        background: "#EEE"
                      }}
                    >
                      <strong>{paymentMetaAddress}</strong>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p
                    style={{
                        padding: "2em",
                      }}>
                      Nakon uspješne uplate na Vaš <b>{contactType}</b> biti će poslan KOD za bezkartično podizanje gotovine.
                      Zatim na najbližem <b>{ATM}</b> bankomatu upišite kod i podignite <b>{amount},00</b> kn.
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>Trenutni status i komentar transakcije:</p>
                  </Col>
                </Row>
                <Row>
                  <Col />
                  <Col sm="6">
                    <h4
                      style={{
                        color: "#333",
                        padding: "10px",
                        background: "rgb(255, 232, 61)",
                        borderRadius: "30px"
                      }}
                    >
                      <strong>{status}</strong>
                    </h4>
                  </Col>
                  <Col />
                </Row>
                {(comment !== '' && comment !== null) &&
                (<Row>
                  <Col />
                  <Col sm="10">
                    <h4
                      style={{
                        color: "#333",
                        padding: "10px",
                        background: "rgb(238, 238, 238)",
                        minHeight: "100px",
                      }}
                    >
                      <strong>{comment}</strong>
                    </h4>
                  </Col>
                  <Col />
                </Row>)
                }

{/* 
                <Row>
                  <Col>
                    <p>Vaš kod za podizanje gotovine:</p>
                  </Col>
                </Row>
                <Row>
                  <Col />
                  <Col sm="6">
                    <h4
                      style={{
                        color: "#333",
                        padding: "10px",
                        background: "rgb(255, 232, 61)",
                        borderRadius: "30px"
                      }}
                    >
                      <strong>******</strong>
                    </h4>
                  </Col>
                  <Col />
                </Row>
*/}

{/*                 
                <Row>
                  <Col />
                  <Col sm="6">
                    <Link to="/getCode">
                      <Button disabled color="success" size="lg">
                        <i
                          className="fa fa-spinner fa-spin"
                          style={{ fontSize: "24px" }}
                        />{" "}
                        Prikaži kod
                      </Button>
                    </Link>
                    <br />
                    <br />
                    <p style={{ fontSize: "15px", color: "#dadada" }}>
                      *Kod će biti prikazan nakon uspješne uplate.
                    </p>
                  </Col>
                  <Col />
                </Row>
 */}
                <Row>
                  <Col />
                  <Col sm="6">
                    <Button disabled color="success" style={{whiteSpace: 'normal', cursor: 'not-allowed', pointerEvents: 'all !important'}} size="lg">
                      Nisam dobio kod, pošalji ponovno!
                    </Button>
                  </Col>
                  <Col />
                </Row>
                <Row>
                  <Col />
                  <Col sm="6">
                    <p style={{ fontSize: "15px", color: "#dadada" }}>
                      *Kod će biti poslan nakon uspješne uplate.
                    </p>
                  </Col>
                  <Col />
                </Row>
                <Row>
                  <Col>
                    <p>Uplatnica je važeća 1 sat od trenutka stvaranja:</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TimerCountdown date={validDate} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      Zapamtite pristupnu šifru kako bi se mogli vratiti na ovu
                      uplatnicu:
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Link to={`/contract/${confirmationNumber}`}>
                      <h4
                        style={{
                          color: "#FFF",
                          minWidth: "210px",
                          padding: "10px",
                          background: "brown",
                          border: "4px dashed rgb(255, 232, 61)"
                        }}
                      >
                        <strong>{confirmationNumber}</strong>
                      </h4>
                    </Link>
                  </Col>
                </Row>
              </Jumbotron>
            </Col>
            <Col />
          </Row>
          <Row>
            <Col>
              <br />
              <Link to="/">
                <Button color="danger" size="lg" style={{}}>
                  Izlaz
                </Button>
              </Link>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contract);


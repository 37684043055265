import gql from "graphql-tag";

export const CreateUserMutation = gql`
  mutation CreateUser(
    $username: String!
    $password: String!
    $role: String!
    $firstName: String!
    $lastName: String!
    $title: String
    $email: String!
    $avatar: String
    $officeAssigned: [String]
    $accountLocked: Int
  ) {
    createUser(
      username: $username
      password: $password
      role: $role
      firstName: $firstName
      lastName: $lastName
      title: $title
      email: $email
      avatar: $avatar
      officeAssigned: $officeAssigned
      accountLocked: $accountLocked
    ) {
      id
    }
  }
`;

export default CreateUserMutation;

export const UPDATE_LANGUAGE_SELECTION = "UPDATE_LANGUAGE_SELECTION";
export const updateLanguageSelection = language => {
  return {
    type: UPDATE_LANGUAGE_SELECTION,
    payload: { languageSelected: language }
  };
};

export const UPDATE_BANK_SELECTOR_SELECTION = "UPDATE_BANK_SELECTOR_SELECTION";
export const updateBankSelectorSelection = ATM => {
  return {
    type: UPDATE_BANK_SELECTOR_SELECTION,
    payload: { atmSelected: ATM }
  };
};

export const UPDATE_AMOUNT_SELECTED = "UPDATE_AMOUNT_SELECTED";
export const updateAmountSelected = amount => {
  return {
    type: UPDATE_AMOUNT_SELECTED,
    payload: { amountSelected: amount }
  };
};

export const UPDATE_PAYMENT_SELECTED = "UPDATE_PAYMENT_SELECTED";
export const updatePaymentSelected = paymentOption => {
  return {
    type: UPDATE_PAYMENT_SELECTED,
    payload: { paymentSelected: paymentOption }
  };
};

export const UPDATE_CONTACT_SELECTED = "UPDATE_CONTACT_SELECTED";
export const updatewayOfContactSelected = wayOfContact => {
  return {
    type: UPDATE_CONTACT_SELECTED,
    payload: { wayOfContactSelected: wayOfContact }
  };
};

export const RESERVATION_FINISHED = "RESERVATION_FINISHED";
export const reservationDone = () => ({
  type: RESERVATION_FINISHED
});

export const CREATE_NEW_CONTRACT = "CREATE_NEW_CONTRACT";
export const createNewTransaction = () => {
  return {
    type: CREATE_NEW_CONTRACT,
    payload: {}
  };
};

export const REFRESH_CONTRACT = "REFRESH_CONTRACT";
export const refreshContract = (confirmationNumber) => {
  return {
    type: REFRESH_CONTRACT,
    payload: {
      confirmationNumber: confirmationNumber,
    }
  };
};

export const REFRESH_CONTRACT_SUCCESS = "REFRESH_CONTRACT_SUCCESS";
export const loadAllContractDataSuccess = (getContract) => {
  return {
    type: REFRESH_CONTRACT_SUCCESS,
    payload: {
      getContract: getContract,
    }
  };
};

export const GET_AVAILABLE_OPTIONS = "GET_AVAILABLE_OPTIONS";
export const getAvailableOptions = () => {
  return {
    type: GET_AVAILABLE_OPTIONS,
    payload: {}
  };
};

export const UPDATE_WAY_OF_CONTACT_FIELD = "UPDATE_WAY_OF_CONTACT_FIELD";
export const updateWayOfContactField = (field, e) => {
  if (field === "EMAIL") {
    return {
      type: UPDATE_WAY_OF_CONTACT_FIELD,
      payload: { email: e.target.value }
    };
  } else {
    // SMS
    return {
      type: UPDATE_WAY_OF_CONTACT_FIELD,
      payload: { phoneNumber: e.target.value }
    };
  }
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { authLogout } from "../../redux/actions/authActions";
import AuthContainer from "../../containers/AuthContainer";
import { Spinner } from "../../containers/Spinner";

const mapDispatchToProps = dispatch => ({
  authLogout: () => dispatch(authLogout())
});

class Logout extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.authLogout();
    }, 1618 /* GOLDEN RATIO */);
  }

  render() {
    return (
      <AuthContainer>
        <p className="text-muted text-center">Odjava u tijeku...</p>
        <Spinner className="d-flex justify-content-center" fontSize="32px" />
      </AuthContainer>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Logout);

import moment from "moment";

export const FORMAT_STANDARD_DATE = "DD-MM-YYYY";
export const FORMAT_QUERY_DATE_TIME = "DD-MM-YYYY HH:mm";

export const formatDate = dateString => {
  if (moment(dateString, moment.ISO_8601, true).isValid()) {
    return moment(dateString)
      .utc()
      .format(FORMAT_STANDARD_DATE);
  } else {
    return dateString;
  }
};

import gql from "graphql-tag";

export const GetContract = gql`
  query getContract($confirmationNumber: String!) {
    getContract(filter: { confirmationNumber: $confirmationNumber }) {
      confirmationNumber
      ATM
      amount
      paymentType
      contactType
      paymentMetaAddress
      paymentMetaAmount
      paymentMetaComment
      firstName
      lastName
      email
      phoneNumber
      validDate
      canceled
      expired
      requested
      paid
      released
      completed
      locked
      createdAt
      updatedAt
      status
      comment
    }
  }
`;

export default GetContract;

import Login from "../views/Login";
import Logout from "../views/Logout";
import Dashboard from "../views/Dashboard";
import ErrorPage from "../views/Error";
import Users from "../views/Users";
import BusinessPlan from "../views/BusinessPlan";
import OfficeDataTable from "../views/OfficeDataTable";

export const ROUTE_TO_DASHBOARD_PAGE = "/planovi";
export const ROUTE_TO_LOGIN_PAGE = "/login";
export const ROUTE_TO_LOGOUT_PAGE = "/logout";
export const ROUTE_TO_USERS_PAGE = "/korisnici";
export const ROUTE_TO_ERROR_PAGE = "/greska";
export const ROUTE_TO_PLAN_PAGE = "/pregled";
export const ROUTE_TO_PLAN_OFFICES = `${ROUTE_TO_PLAN_PAGE}/:id([0-9]+)`;
export const ROUTE_TO_OFFICE_DATA_TABLE = `${ROUTE_TO_PLAN_OFFICES}/:officeId([0-9]+)/:policyType([a-zA-Z0-9]+)`;
export const ROUTE_TO_CONTRACT_PAGE = "/contract";

export const routes = [
  {
    path: ROUTE_TO_DASHBOARD_PAGE,
    exact: true,
    name: "Planovi prodaje",
    component: Dashboard
  },
  {
    path: ROUTE_TO_PLAN_PAGE,
    exact: true,
    name: "Pregled podataka",
    component: BusinessPlan
  },
  {
    path: ROUTE_TO_PLAN_OFFICES,
    exact: true,
    name: "Pregled ureda",
    component: BusinessPlan
  },
  {
    path: ROUTE_TO_OFFICE_DATA_TABLE,
    exact: true,
    name: "Podaci za ured",
    component: OfficeDataTable
  },
  { path: ROUTE_TO_LOGIN_PAGE, exact: true, name: "Prijava", component: Login },
  {
    path: ROUTE_TO_LOGOUT_PAGE,
    exact: true,
    name: "Odjava",
    component: Logout
  },
  {
    path: ROUTE_TO_USERS_PAGE,
    exact: true,
    name: "Postavke korisnika",
    component: Users
  },
  {
    path: ROUTE_TO_ERROR_PAGE,
    exact: true,
    name: "Greška",
    component: ErrorPage
  }
];

import { get } from "lodash-es";

const INVALID_AUTHENTICATION_TOKEN = "INVALID_AUTHENTICATION_TOKEN";
const INVALID_CORS_POLICY = "INVALID_CORS_POLICY";
const AUTHENTICATION_TOKEN_HAS_EXPIRED = "AUTHENTICATION_TOKEN_HAS_EXPIRED";
const AUTH_VALID_BUT_USER_DATA_MISSING = "AUTH_VALID_BUT_USER_DATA_MISSING";
const AUTHENTICATION_DATA_IS_MISSING = "AUTHENTICATION_DATA_IS_MISSING";
const INVALID_TOKEN_FORMAT = "INVALID_TOKEN_FORMAT";
const INVALID_INPUT_DATE_FORMAT = "INVALID_INPUT_DATE_FORMAT";
const WHILE_CREATING_NEW_USER = "WHILE_CREATING_NEW_USER";
const RESOLVER_DISABLED = "RESOLVER_DISABLED";
const INVALID_RESOLVER_SPECIFIC_TOKEN = "INVALID_RESOLVER_SPECIFIC_TOKEN";
const CANT_UPDATE_DATA_TO_DATABASE = "CANT_UPDATE_DATA_TO_DATABASE";
const PASSWORD_IS_INVALID = "PASSWORD_IS_INVALID";
const CANT_CREATE_AUDIT_DATA = "CANT_CREATE_AUDIT_DATA";
const INVALID_APPLICATION_ID_OR_INVALID_TOKEN =
  "INVALID_APPLICATION_ID_OR_INVALID_TOKEN";
const APPLICATION_REFERENCE_PROBLEM = "APPLICATION_REFERENCE_PROBLEM";
const DUPLICATE_MAIL_DETECTED = "DUPLICATE_MAIL_DETECTED";
const INVALID_CAPTCHA = "INVALID_CAPTCHA";
const USER_HAS_NO_ROLE = "USER_HAS_NO_ROLE";
const RESOLVER_ONLY_FOR_ADMINS = "RESOLVER_ONLY_FOR_ADMINS";
const USER_HAS_NO_ACCESS = "USER_HAS_NO_ACCESS";
const WHILE_LOOKING_FOR_SPECIFIC_USER = "WHILE_LOOKING_FOR_SPECIFIC_USER";
const NO_SPECIFIC_USER_OR_PASSWORD_COMBINATION =
  "NO_SPECIFIC_USER_OR_PASSWORD_COMBINATION";
const MORE_THAN_ONE_USER_OR_PASSWORD_COMBINATION_FOUND =
  "MORE_THAN_ONE_USER_OR_PASSWORD_COMBINATION_FOUND";
const ACCOUNT_IS_LOCKED = "ACCOUNT_IS_LOCKED";
const ACCOUNT_IS_LOCKED_TOO_MANY_FAILED_ATTEMPTS =
  "ACCOUNT_IS_LOCKED_TOO_MANY_FAILED_ATTEMPTS";
const UNKNOWN_ERROR = "UNKNOWN_ERROR";
const MAINTENANCE_IN_PROGRESS = "MAINTENANCE_IN_PROGRESS";

export const ERRORS_MAP = [
  {
    key: INVALID_AUTHENTICATION_TOKEN,
    code: 401,
    message: "Autentikacijski token nije validan."
  },
  // Invalid CORS policy.
  {
    key: INVALID_CORS_POLICY,
    code: 401,
    message: "Nije validan Cross-origin resource sharing (CORS) policy."
  },
  // User data is missing when using provided auth token.
  {
    key: AUTHENTICATION_DATA_IS_MISSING,
    code: 401,
    message: "Podaci za prijavu nisu pronađeni"
  },
  // Token has expired
  {
    key: AUTHENTICATION_TOKEN_HAS_EXPIRED,
    code: 440,
    message: "Token je istekao. Prijavite se ponovno."
  },
  // Token is valid but unknown user
  {
    key: AUTH_VALID_BUT_USER_DATA_MISSING,
    code: 418,
    message:
      "Token je validan ali korisnik kojem je dodijeljen više ne postoji."
  },
  // User has no roles set
  {
    key: USER_HAS_NO_ROLE,
    code: 401,
    message: "Nemate dodijeljena prava za pristup zatraženom resursu."
  },
  // ##################################################################
  {
    key: RESOLVER_ONLY_FOR_ADMINS,
    code: 401,
    message: "This resolver is only for admins and super admins."
  },
  {
    key: USER_HAS_NO_ACCESS,
    code: 401,
    message: "Vaš korisnički račun nema pristup zatraženom resursu."
  },
  // ##################################################################
  {
    key: INVALID_CAPTCHA,
    code: 401,
    message: "Captcha nije validna."
  },
  {
    key: PASSWORD_IS_INVALID,
    code: 401,
    message: "Trenutna lozinka nije validna."
  },
  // ##################################################################
  {
    key: WHILE_LOOKING_FOR_SPECIFIC_USER,
    code: 409,
    message: "Došlo je do greške prilikom traženja korisnika u bazi podataka."
  },
  {
    key: NO_SPECIFIC_USER_OR_PASSWORD_COMBINATION,
    code: 410,
    message: "Pogrešno korisničko ime ili lozinka!"
  },
  {
    key: MORE_THAN_ONE_USER_OR_PASSWORD_COMBINATION_FOUND,
    code: 411,
    message:
      "Pronađeno je više od jednog korisnika s tim korisničkim imenom i lozinkom. Molimo vas da kontaktirate administratora."
  },
  {
    key: ACCOUNT_IS_LOCKED,
    code: 412,
    message:
      "Korisnički račun je zaključan iz sigurnosnih razloga. Molimo vas da kontaktirate administratora."
  },
  {
    key: ACCOUNT_IS_LOCKED_TOO_MANY_FAILED_ATTEMPTS,
    code: 413,
    message:
      "Korisnički račun je zaključan zbog previše neuspješnih pokušaja prijave. Molimo vas da kontaktirate administratora."
  },
  // ##################################################################
  {
    key: RESOLVER_DISABLED,
    code: 800,
    message: "Onemogućeno korištenje resolvera."
  },
  {
    key: INVALID_RESOLVER_SPECIFIC_TOKEN,
    code: 801,
    message: "Token za resolver nije validan."
  },
  {
    key: WHILE_CREATING_NEW_USER,
    code: 801,
    message: "Došlo je do greške prilikom izrade novog korisnika."
  },
  // ##################################################################

  // ##################################################################
  // In situations where provided applicationToken is in invalid format.
  {
    key: INVALID_TOKEN_FORMAT,
    code: 718,
    message: "Nije validan format tokena."
  },
  {
    key: INVALID_INPUT_DATE_FORMAT,
    code: 701,
    message: "Nije validan format unesenog datuma."
  },
  // ##################################################################
  {
    key: CANT_UPDATE_DATA_TO_DATABASE,
    code: 716,
    message: "Nije moguće ažurirati podatke u bazi."
  },
  {
    key: CANT_CREATE_AUDIT_DATA,
    code: 715,
    message: "Nije moguće kreirati podatke o reviziji."
  },
  {
    key: INVALID_APPLICATION_ID_OR_INVALID_TOKEN,
    code: 730,
    message: "Nepoznati aplikacijski ID ili token."
  },
  {
    key: APPLICATION_REFERENCE_PROBLEM,
    code: 731,
    message:
      "Došlo je do problema pri referenciranju aplikacije. Molimo vas da kontaktirate administratora."
  },
  {
    key: DUPLICATE_MAIL_DETECTED,
    code: 777,
    message: "Detektiran duplicirani mail!"
  },
  // ##################################################################
  // Any error not defined in ERRORS object.
  {
    key: UNKNOWN_ERROR,
    code: 999,
    message: "Došlo je do greške na serveru"
  },
  {
    key: MAINTENANCE_IN_PROGRESS,
    code: 400,
    title: "Stranica trenutno ne radi zbog održavanja",
    message:
      "Žao nam je zbog prouzročene neugodnosti. Radimo sve što je u našoj moći kako bi stranica što prije proradila."
  }
];

export const getErrorObject = e => {
  const gqErrors = get(e, "graphQLErrors", []);
  const reveivedError = ERRORS_MAP.find(error => {
    return gqErrors.find(element => {
      return get(element, "key", "") === error.key ? error : null;
    });
  });

  let errorToReturn = {};
  if (reveivedError) {
    errorToReturn = reveivedError;
  } else {
    errorToReturn = ERRORS_MAP.find(error => {
      return error.key === UNKNOWN_ERROR ? error : null;
    });
  }
  return errorToReturn;
};

export const isMaintenanceInProgress = error => {
  return error.key === MAINTENANCE_IN_PROGRESS;
};

export const isAuthTokenInvalid = error => {
  return [
    INVALID_AUTHENTICATION_TOKEN,
    AUTHENTICATION_TOKEN_HAS_EXPIRED,
    AUTH_VALID_BUT_USER_DATA_MISSING
  ].includes(error.key);
};

import gql from "graphql-tag";

export const CreateNewContractMutation = gql`
  mutation createNewContract(
    $ATM: String!
    $amount: Int!
    $paymentType: String!
    $contactType: String!
    $language: String
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
  ) {
    createContract(
      ATM: $ATM
      amount: $amount
      paymentType: $paymentType
      contactType: $contactType
      language: $language
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
    ) {
      confirmationNumber
    }
  }
`;

export default CreateNewContractMutation;

import { select, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  REDIRECT_TO_LOGIN,
  REDIRECT_TO_DASHBOARD,
  REDIRECT_TO_PLAN_PAGE,
  REDIRECT_TO_OFFICE_DATA_TABLE_PAGE
} from "../actions/navigationActions";
import { GENERAL_ERROR } from "../actions/errorActions";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTH_LOGOUT
} from "../actions/authActions";
import * as routes from "../../constants/routes";

/** Worker Sagas **/

function* redirectToLoginPageWorker() {
  const path = yield select(state => state.router.location.pathname);
  if (path !== routes.ROUTE_TO_LOGIN_PAGE) {
    yield put(push(routes.ROUTE_TO_LOGIN_PAGE));
  }
}

function* redirectOnSuccessfulLoginWorker() {
  const path = yield select(state => state.router.location.pathname);
  if (path === routes.ROUTE_TO_LOGIN_PAGE) {
    yield put(push(routes.ROUTE_TO_DASHBOARD_PAGE));
  } else {
    yield put(push(path));
  }
}

function* redirectToDashboardPageWorker() {
  yield put(push(routes.ROUTE_TO_DASHBOARD_PAGE));
}

function* redirectToErrorPageWorker() {
  yield put(push(routes.ROUTE_TO_ERROR_PAGE));
}

function* redirectToPlanPageWorker(action) {
  yield put(push(`${routes.ROUTE_TO_PLAN_PAGE}/${action.payload}`));
}

function* redirectToOfficeDataTablePageWorker(action) {
  yield put(
    push(
      `${routes.ROUTE_TO_PLAN_PAGE}/${action.payload.planId}/${
        action.payload.officeId
      }/${action.payload.policyType}`
    )
  );
}

/** Watcher Sagas **/

export function* redirectToLoginPageWatcher() {
  yield takeLatest(REDIRECT_TO_LOGIN, redirectToLoginPageWorker);
}

export function* redirectToDashboardPageWatcher() {
  yield takeLatest(REDIRECT_TO_DASHBOARD, redirectToDashboardPageWorker);
}

export function* redirectToPlanPageWatcher() {
  yield takeLatest(REDIRECT_TO_PLAN_PAGE, redirectToPlanPageWorker);
}

export function* redirectToOfficeDataTablePageWatcher() {
  yield takeLatest(
    REDIRECT_TO_OFFICE_DATA_TABLE_PAGE,
    redirectToOfficeDataTablePageWorker
  );
}

export function* loginSuccessWatcher() {
  yield takeLatest(LOGIN_SUCCESS, redirectOnSuccessfulLoginWorker);
}

export function* loginFailureWatcher() {
  yield takeLatest(LOGIN_FAILURE, redirectToLoginPageWorker);
}

export function* logoutWatcher() {
  yield takeLatest(AUTH_LOGOUT, redirectToLoginPageWorker);
}

export function* redirectToErrorPageWatcher() {
  yield takeLatest(GENERAL_ERROR, redirectToErrorPageWorker);
}

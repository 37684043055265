import React, { Component } from "react";
import { connect } from "react-redux";
import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
import { routes } from "../../constants/routes";
// sidebar nav config
import { sidebarNavigation } from "./_sidebarNavConfig";
import { Footer } from "./Footer";
import Header from "./Header";

const mapStateToProps = state => ({
  user: state.authentication.user,
  router: state.router
});

class Layout extends Component {
  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <Header user={this.props.user} />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarNav
              navConfig={sidebarNavigation(this.props.user.role)}
              {...this.props.children.props}
            />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            {this.props.children}
          </main>
        </div>
        <AppFooter>
          <Footer />
        </AppFooter>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Layout);

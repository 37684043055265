import gql from "graphql-tag";

export const UsersQuery = gql`
  query users {
    userList: getUsers {
      id
      username
      firstName
      lastName
      role
      title
      email
      avatar
      officeAssigned
      passwordExpires
      passwordExpired
      accountLocked
      updatedAt
      createdAt
    }
  }
`;

export default UsersQuery;

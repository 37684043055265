export const LOAD_USERS = "LOAD_USERS";
export const loadUsers = () => ({
  type: LOAD_USERS
});

export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const loadUsersSuccess = users => ({
  type: LOAD_USERS_SUCCESS,
  payload: users
});

export const LOAD_USERS_FAILURE = "LOAD_USERS_FAILURE";
export const loadUsersFailure = () => ({
  type: LOAD_USERS_FAILURE
});

export const CREATE_USER = "CREATE_USER";
export const createUser = user => ({
  type: CREATE_USER,
  payload: user
});

export const CREATE_USER_FEEDBACK = "CREATE_USER_FEEDBACK";
export const createUserFeedback = () => ({
  type: CREATE_USER_FEEDBACK
});

export const UPDATE_USER = "UPDATE_USER";
export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user
});

export const UPDATE_USER_FEEDBACK = "UPDATE_USER_FEEDBACK";
export const updateUserFeedback = () => ({
  type: UPDATE_USER_FEEDBACK
});

export const DELETE_USER = "DELETE_USER";
export const deleteUser = userId => ({
  type: DELETE_USER,
  payload: userId
});

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const deleteUserSuccess = userId => ({
  type: DELETE_USER_SUCCESS,
  payload: userId
});

export const LOAD_OFFICES = "LOAD_OFFICES";
export const loadMainOffices = () => ({
  type: LOAD_OFFICES
});

export const LOAD_OFFICES_SUCCESS = "LOAD_OFFICES_SUCCESS";
export const loadMainOfficesSucess = officeList => ({
  type: LOAD_OFFICES_SUCCESS,
  payload: officeList
});

export const LOAD_REGIONS = "LOAD_REGIONS";
export const loadRegions = () => ({
  type: LOAD_REGIONS
});

export const LOAD_REGIONS_SUCCESS = "LOAD_REGIONS_SUCCESS";
export const loadRegionsSucess = regionList => ({
  type: LOAD_REGIONS_SUCCESS,
  payload: regionList
});

import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback
} from "reactstrap";
import { ADMIN, DIREKTOR, KORISNIK, SUPER_ADMIN } from "../../constants/roles";
import { get } from "lodash-es";
import { validate } from "../../utils/validation";

import { loadMainOffices, loadRegions } from "../../redux/actions/usersActions";

const mapStateToProps = state => ({
  officeList: state.users.officeList,
  regionList: state.users.regionList
});

class UserModal extends React.Component {
  constructor(props) {
    super(props);

    this.userId = get(this.props, "user.id", "");

    this.initialFormData = {
      id: {
        value: this.userId,
        error: null,
        invalid: true,
        displayName: "id"
      },
      firstName: {
        value: get(this.props, "user.firstName", ""),
        error: null,
        invalid: true,
        displayName: "ime",
        validation: {
          required: true
        }
      },
      lastName: {
        value: get(this.props, "user.lastName", ""),
        error: null,
        invalid: true,
        displayName: "prezime",
        validation: {
          required: true
        }
      },
      officeAssigned: {
        value: get(this.props, "user.officeAssigned", []).map(entry => ({
          value: entry
        })),
        error: null,
        invalid: true,
        displayName: "ured"
      },
      username: {
        value: get(this.props, "user.username", ""),
        error: null,
        invalid: true,
        displayName: "korisničko ime",
        validation: {
          required: true
        }
      },
      password: {
        value: "",
        error: null,
        invalid: true,
        displayName: "lozinka",
        validation: {
          required: !this.userId
        }
      },
      repeatPassword: {
        value: "",
        error: null,
        invalid: true,
        displayName: "ponovi lozinku",
        validation: {
          required: !this.userId,
          matches: ""
        }
      },
      email: {
        value: get(this.props, "user.email", ""),
        error: null,
        invalid: true,
        displayName: "email",
        validation: {
          required: true,
          email: true
        }
      },
      role: {
        value: get(this.props, "user.role", "user"),
        error: null,
        invalid: true,
        displayName: "uloga",
        validation: {
          required: true,
          enum: [ADMIN, KORISNIK, DIREKTOR]
        }
      },
      accountLocked: {
        value: Number(get(this.props, "user.accountLocked", 0)),
        error: null,
        invalid: false,
        displayName: "status",
        validation: {
          required: true,
          enum: [0, 1]
        }
      },
      avatar: {
        value: get(this.props, "user.avatar", ""),
        error: null,
        invalid: false,
        displayName: "avatar"
      }
    };

    this.state = {
      focused: false,
      submitted: false,
      isFormValid: false,
      isOfficeAndRegionListFetched: false,
      submissionErrorMessage: null,
      selectOptionsList: [],
      formData: { ...this.initialFormData }
    };
  }

  componentDidMount() {
    // only load office list once
    !this.props.officeList && this.props.loadMainOffices();
    !this.props.regionList && this.props.loadRegions();

    // horrible hack - autocomplete="off" and "new-password" still unwantedly assign password in FF 63
    // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
    setTimeout(() => this.inputHandler("password", ""), 400);
  }

  componentDidUpdate() {
    const {
      isOfficeAndRegionListFetched,
      formData,
      selectOptionsList
    } = this.state;

    // server only returns id's of assigned offices/regions/ALL, without labels/names
    // once loadMainOffices and loadRegions are resolved, fill list with labels
    if (
      !isOfficeAndRegionListFetched &&
      this.props.officeList &&
      this.props.regionList &&
      formData.officeAssigned.value
    ) {
      let assignedLabels = formData.officeAssigned.value.map(entry => {
        let labelToAssign = "";

        // search in MainOffices list
        if (entry.value.startsWith("MO-"))
          labelToAssign = get(
            this.props.officeList.find(row => row.value === entry.value),
            "label",
            "Nepoznat glavni ured"
          );

        // search in Regions list
        if (entry.value.startsWith("RE-"))
          labelToAssign = get(
            this.props.regionList.find(row => row.value === entry.value),
            "label",
            "Nepoznata regija"
          );

        // ALL - access to every region and main office
        if (entry.value.startsWith("ALL")) labelToAssign = "SVE REGIJE I UREDI";

        return {
          value: entry.value,
          label: labelToAssign
        };
      });

      this.setState({
        isOfficeAndRegionListFetched: true,
        formData: {
          ...formData,
          officeAssigned: { ...formData.officeAssigned, value: assignedLabels }
        }
      });
    }

    // only assign this if it's empty, and region and office list have been loaded
    if (
      selectOptionsList &&
      selectOptionsList.length === 0 &&
      this.props.officeList &&
      this.props.regionList
    )
      this.setState({
        selectOptionsList: [
          {
            label: "Odaberi sve",
            options: [{ value: "ALL", label: "SVE REGIJE I UREDI" }]
          },
          { label: "Regije", options: this.props.regionList },
          { label: "Glavni uredi", options: this.props.officeList }
        ]
      });
  }

  inputHandler = (field, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: {
          ...this.state.formData[field],
          value: value
        },
        ...(field === "password" && {
          repeatPassword: {
            ...this.state.formData.repeatPassword,
            validation: {
              ...this.state.formData.repeatPassword.validation,
              matches: value
            }
          }
        })
      }
    });
  };

  createOrUpdateUser = () => {
    let formDataObject = { ...this.state.formData };
    let isFormValid = true;
    for (let field in formDataObject) {
      const validation = validate(formDataObject[field]);
      formDataObject[field] = {
        ...formDataObject[field],
        invalid: validation.invalid,
        error: validation.errors[0]
      };
      isFormValid = isFormValid && !validation.invalid;
    }

    this.setState(
      { isFormValid, submitted: true, formData: formDataObject },
      () => {
        if (isFormValid) {
          this.setState({ submissionErrorMessage: null }, () => {
            let payloadObject = {
              ...(this.state.formData.id.value && {
                id: this.state.formData.id.value
              })
            };
            for (let inputObject in this.state.formData) {
              if (inputObject === "repeatPassword") continue;

              switch (inputObject) {
                case "avatar":
                  if (this.state.formData.avatar.value)
                    payloadObject.avatar = this.state.formData.avatar.value;
                  else {
                    switch (this.state.formData.role.value) {
                      case ADMIN:
                        payloadObject.avatar = "./img/avatar/admin.jpg";
                        break;
                      case DIREKTOR:
                        payloadObject.avatar = "./img/avatar/elcapo.jpg";
                        break;
                      case KORISNIK:
                        payloadObject.avatar = "./img/avatar/radnik.jpg";
                        break;
                      default:
                        payloadObject.avatar = "./img/avatar/default-user.png";
                    }
                  }
                  break;
                case "officeAssigned":
                  const officesIdArray = this.state.formData.officeAssigned.value.map(
                    entry => entry.value
                  );
                  payloadObject.officeAssigned = officesIdArray;
                  break;
                default:
                  if (this.state.formData[inputObject].value)
                    payloadObject[inputObject] = this.state.formData[
                      inputObject
                    ].value;
              }
            }

            this.props.userModalAction(payloadObject);
            this.setState({
              submitted: false,
              formData: { ...this.initialFormData }
            });
            this.props.toggle();
          });
        } else {
          this.setState({
            submissionErrorMessage:
              "Ispravite netočne unose pa pokušajte ponovno."
          });
        }
      }
    );
  };

  formFeedback = () => {
    if (this.state.submitted && !this.state.isFormValid) {
      return (
        <Row className="w-100 mt-2 no-gutters">
          <Col xs="12">
            <div className="text-center form-feedback">
              {this.state.submissionErrorMessage}
            </div>
          </Col>
        </Row>
      );
    }
  };

  render() {
    return (
      <Modal
        backdrop="static"
        size="lg"
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
      >
        <ModalHeader className="text-uppercase" toggle={this.props.toggle}>
          Korisnički podaci
        </ModalHeader>
        <ModalBody>
          <Row className="py-3 px-0 px-sm-3 user-form">
            <Col sm={6} md={6} xl={6}>
              <FormGroup row>
                <Col>
                  <Label for="id">ID</Label>
                  <Input
                    type="text"
                    id="id"
                    value={this.state.formData.id.value}
                    disabled
                  />
                  <FormFeedback />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="firstName">* Ime</Label>
                  <Input
                    type="text"
                    id="firstName"
                    value={this.state.formData.firstName.value}
                    invalid={
                      this.state.submitted &&
                      this.state.formData.firstName.invalid
                    }
                    onChange={event =>
                      this.inputHandler("firstName", event.target.value)
                    }
                  />
                  <FormFeedback>
                    {this.state.formData.firstName.error}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="lastName">* Prezime</Label>
                  <Input
                    type="text"
                    id="lastName"
                    value={this.state.formData.lastName.value}
                    invalid={
                      this.state.submitted &&
                      this.state.formData.lastName.invalid
                    }
                    onChange={event =>
                      this.inputHandler("lastName", event.target.value)
                    }
                  />
                  <FormFeedback>
                    {this.state.formData.lastName.error}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="username">* Korisničko ime</Label>
                  <Input
                    type="text"
                    id="username"
                    value={this.state.formData.username.value}
                    invalid={
                      this.state.submitted &&
                      this.state.formData.username.invalid
                    }
                    onChange={event =>
                      this.inputHandler("username", event.target.value)
                    }
                  />
                  <FormFeedback>
                    {this.state.formData.username.error}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="email">* Email adresa</Label>
                  <Input
                    type="text"
                    id="email"
                    value={this.state.formData.email.value}
                    invalid={
                      this.state.submitted && this.state.formData.email.invalid
                    }
                    onChange={event =>
                      this.inputHandler("email", event.target.value)
                    }
                  />
                  <FormFeedback>{this.state.formData.email.error}</FormFeedback>
                </Col>
              </FormGroup>
            </Col>
            <Col sm={6} md={6} xl={6}>
              <FormGroup row>
                <Col className="mb-3 mb-sm-0" xs={12} sm={12}>
                  <Label for="role">* Uloga</Label>
                  <Input
                    type="select"
                    id="role"
                    invalid={
                      this.state.submitted && this.state.formData.role.invalid
                    }
                    value={this.state.formData.role.value}
                    onChange={event =>
                      this.inputHandler("role", event.target.value)
                    }
                  >
                    <option value={KORISNIK}>Korisnik</option>
                    <option value={DIREKTOR}>Direktor</option>
                    <option value={ADMIN}>Administrator</option>
                    <option value={SUPER_ADMIN}>Super Admin</option>
                  </Input>
                  <FormFeedback>{this.state.formData.role.error}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col className="mb-3 mb-sm-0" xs={12} sm={12}>
                  <Label for="accountLocked">* Status</Label>
                  <Input
                    type="select"
                    id="accountLocked"
                    value={this.state.formData.accountLocked.value}
                    invalid={
                      this.state.submitted &&
                      this.state.formData.accountLocked.invalid
                    }
                    onChange={event =>
                      this.inputHandler(
                        "accountLocked",
                        parseInt(event.target.value, 10)
                      )
                    }
                  >
                    <option value="0">Aktivan</option>
                    <option value="1">Neaktivan</option>
                  </Input>
                  <FormFeedback>
                    {this.state.formData.accountLocked.error}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="officeAssigned">Dodijeljene regije i uredi</Label>
                  <Select
                    id="officeAssigned"
                    isMulti
                    value={this.state.formData.officeAssigned.value}
                    options={this.state.selectOptionsList}
                    onChange={value =>
                      this.inputHandler("officeAssigned", value)
                    }
                  />
                  <FormFeedback>
                    {this.state.formData.officeAssigned.error}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row hidden>
                {/* dummy 'username' field, browsers attempt to assign username to closest text field near the password field below  */}
                <Col>
                  <Input type="text" />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="password">
                    {!this.state.formData.id.value && "* "}Lozinka
                  </Label>
                  <Input
                    type="password"
                    id="password"
                    value={this.state.formData.password.value}
                    invalid={
                      this.state.submitted &&
                      this.state.formData.password.invalid
                    }
                    onChange={event =>
                      this.inputHandler("password", event.target.value)
                    }
                  />
                  <FormFeedback>
                    {this.state.formData.password.error}
                  </FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Label for="repeatPassword">
                    {!this.state.formData.id.value && "* "}Ponovi lozinku
                  </Label>
                  <Input
                    type="password"
                    id="repeatPassword"
                    value={this.state.formData.repeatPassword.value}
                    invalid={
                      this.state.submitted &&
                      this.state.formData.repeatPassword.invalid
                    }
                    onChange={event =>
                      this.inputHandler("repeatPassword", event.target.value)
                    }
                  />
                  <FormFeedback>
                    {this.state.formData.repeatPassword.error}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </Col>
            {this.formFeedback()}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className="hok-button" outline onClick={this.props.toggle}>
            Odustani
          </Button>{" "}
          <Button
            className="hok-button"
            color="success"
            onClick={this.createOrUpdateUser}
          >
            Spremi
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  { loadMainOffices, loadRegions }
)(UserModal);

import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";

import { updatePaymentSelected } from "../../redux/actions/onlineBankomatActions";

import { Col, Card, CardImg, CardBody, CardText } from "reactstrap";

import creditCardImage from "./../../img/pay-with-cc.png";
import bitcoinImage from "./../../img/pay-with-btc.png";
import ethereumImage from "./../../img/pay-with-eth.png";
import phoneCallImage from "./../../img/pay-with-phone-call.png";

const mapStateToProps = state => ({
  onlineBankomat: state.onlineBankomat
});

const mapDispatchToProps = dispatch => ({
  updatePaymentSelected: paymentOption =>
    dispatch(updatePaymentSelected(paymentOption))
});

class PaymentSelector extends Component {
  _selectDifferentPaymentOption = paymentOption => () => {
    this.props.updatePaymentSelected(paymentOption);
  };

  render() {
    const paymentAvailable = get(this.props, "onlineBankomat.paymentAvailable");
    const paymentEnabled = get(this.props, "onlineBankomat.paymentEnabled");

    const paymentSelected = get(this.props, "onlineBankomat.paymentSelected");
    const atmSelected = get(this.props, "onlineBankomat.atmSelected");

    const amountSelected = get(this.props, "onlineBankomat.amountSelected");

    const cardClassNameFunction = payment => {
      if (amountSelected === "NONE" || atmSelected === "NONE") {
        return "disabled";
      }
      if (paymentEnabled.includes(payment)) {
        if (paymentSelected === payment) {
          return "selected";
        } else {
          return "not-selected";
        }
      }
      return "disabled";
    };

    return (
      <div className="row paymentSelector">
        <Col>
          <ul>
            {paymentAvailable.includes("CREDITCARD") && (
              <li>
                <Card
                  className={cardClassNameFunction("CREDITCARD")}
                  onClick={this._selectDifferentPaymentOption("CREDITCARD")}
                  style={{ cursor: "pointer", width: "166px" }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={creditCardImage}
                    alt="Credit Card"
                  />
                  <CardBody>
                    <CardText>Credit Card</CardText>
                  </CardBody>
                </Card>
              </li>
            )}

            {paymentAvailable.includes("BTC") && (
              <li>
                <Card
                  className={cardClassNameFunction("BTC")}
                  onClick={this._selectDifferentPaymentOption("BTC")}
                  style={{ cursor: "pointer", width: "166px" }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={bitcoinImage}
                    alt="Bitcoin (BTC)"
                  />
                  <CardBody>
                    <CardText>Bitcoin (BTC)</CardText>
                  </CardBody>
                </Card>
              </li>
            )}

            {paymentAvailable.includes("ETH") && (
              <li>
                <Card
                  className={cardClassNameFunction("ETH")}
                  onClick={this._selectDifferentPaymentOption("ETH")}
                  style={{ cursor: "pointer", width: "166px" }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={ethereumImage}
                    alt="Etherium (ETH)"
                  />
                  <CardBody>
                    <CardText>Etherium (ETH)</CardText>
                  </CardBody>
                </Card>
              </li>
            )}

            {paymentAvailable.includes("PHONE") && (
              <li>
                <Card
                  className={cardClassNameFunction("PHONE")}
                  onClick={this._selectDifferentPaymentOption("PHONE")}
                  style={{ cursor: "pointer", width: "166px" }}
                >
                  <CardImg
                    top
                    width="100%"
                    src={phoneCallImage}
                    alt="Phone call"
                  />
                  <CardBody>
                    <CardText>Poziv</CardText>
                  </CardBody>
                </Card>
              </li>
            )}
          </ul>
        </Col>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSelector);

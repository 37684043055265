import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { createNewTransaction } from "./../redux/actions/onlineBankomatActions";

import "./../css/defaultScreen.css";

const mapDispatchToProps = dispatch => ({
  createNewTransaction: () => dispatch(createNewTransaction()),
});

class ReservationInProcess extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.createNewTransaction();
    }, 1618 /* GOLDEN RATIO */);
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <div className="main-area center-text">
          <div className="display-table">
            <div className="display-table-cell">
              <h1 className="title">
                <b>Rezervacija novčanih sredstava u tijeku...</b>
              </h1>
              <p className="desc font-white" />

              <Row>
                <Col>
                  <br />
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: "60px" }}
                  />
                  <br />
                  <br />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(ReservationInProcess);

import gql from "graphql-tag";

export const GetMainOfficesQuery = gql`
  query getMainOffices {
    getMainOffices: getMainOffices {
      mainOfficeId
      mainOfficeName
    }
  }
`;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import { get } from "lodash-es";

import "./../css/defaultScreen.css";

const mapStateToProps = state => ({
  onlineBankomat: state.onlineBankomat
});

const mapDispatchToProps = dispatch => ({});

class DefaultScreen extends Component {
  render() {
    const languageSelected =
      get(this.props, "onlineBankomat.languageSelected") === "NONE"
        ? "HRV"
        : get(this.props, "onlineBankomat.languageSelected", "HRV");

    let LANGUAGE_START_BUTTON = "";
    let LANGUAGE_MOTO = "";
    if (languageSelected === "HRV") {
      LANGUAGE_START_BUTTON = "Kreni!";
      LANGUAGE_MOTO = "Nova dimenzija bankomata";
    }
    if (languageSelected === "ENG") {
      LANGUAGE_START_BUTTON = "Start!";
      LANGUAGE_MOTO = "Make ATM great again";
    }

    return (
      <div style={{ height: "100%" }}>
        <div className="main-area center-text">
          <div className="display-table">
            <div className="display-table-cell">
              <h1 className="title">
                <b>Online bankomat!</b>
              </h1>
              <p className="desc font-white">{LANGUAGE_MOTO}</p>

              <Row>
                <Col>
                  <br />
                  <Link to="/bankomat">
                    <Button color="success" size="lg" style={{}}>
                      {LANGUAGE_START_BUTTON}
                    </Button>
                  </Link>
                  <br />
                  <br />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultScreen);

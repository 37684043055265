import React, { Component } from "react";
import { Row, Col, FormGroup, Input } from "reactstrap";
import PlanDataCards from "./PlanDataCards";

class FilterForm extends Component {
  state = {
    submitted: false,
    formData: {
      region: {
        value: "*",
        error: null,
        invalid: true,
        displayName: "region"
      },
      mainOffice: {
        value: "*",
        error: null,
        invalid: true,
        displayName: "glavni ured"
      },
      office: {
        value: "*",
        error: null,
        invalid: true,
        displayName: "ured"
      },
      policy: {
        value: "*",
        error: null,
        invalid: true,
        displayName: "proizvod"
      }
    }
  };

  inputHandler = (field, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: {
          ...this.state.formData[field],
          value: value
        },
        ...(field === "region" && {
          mainOffice: { ...this.state.formData.mainOffice, value: "*" },
          office: { ...this.state.formData.office, value: "*" }
        }),
        ...(field === "mainOffice" && {
          office: { ...this.state.formData.office, value: "*" }
        })
      }
    });
  };

  renderRegionOptions = () =>
    this.props.singlePlan.regions.map(region => (
      <option key={region.regionId} value={region.regionId}>
        {region.regionName}
      </option>
    ));

  renderMainOfficeOptions = () => {
    let mainOffices = [...this.props.singlePlan.mainOffices];
    if (this.state.formData.region.value !== "*") {
      mainOffices = mainOffices.filter(
        mainOffice =>
          parseInt(this.state.formData.region.value, 10) === mainOffice.regionId
      );
    }
    let result = mainOffices.map(mainOffice => (
      <option key={mainOffice.mainOfficeId} value={mainOffice.mainOfficeId}>
        {mainOffice.mainOfficeName}
      </option>
    ));
    result.length > 1 &&
      result.unshift(
        <option key="allMainOffices" value="*">
          Svi glavni uredi
        </option>
      );
    return result;
  };

  renderOfficeOptions = () => {
    let offices = [...this.props.singlePlan.offices];
    if (this.state.formData.mainOffice.value !== "*") {
      offices = offices.filter(
        office =>
          parseInt(this.state.formData.mainOffice.value, 10) ===
          office.mainOfficeId
      );
    } else if (this.state.formData.region.value !== "*") {
      offices = offices.filter(
        office =>
          parseInt(this.state.formData.region.value, 10) === office.regionId
      );
    }
    let result = offices.map(office => (
      <option key={office.officeId} value={office.officeId}>
        {office.officeName}
      </option>
    ));
    result.length > 1 &&
      result.unshift(
        <option key="allOffices" value="*">
          Svi uredi
        </option>
      );
    return result;
  };

  renderPolicyOptions = () => {
    return this.props.singlePlan.policies.map(policy => (
      <option key={policy.policyType} value={policy.policyType}>
        {policy.policyType}
      </option>
    ));
  };

  filteredOffices = () => {
    let offices = [...this.props.singlePlan.offices];
    return offices.filter(office => {
      if (this.state.formData.office.value !== "*") {
        return (
          parseInt(this.state.formData.office.value, 10) === office.officeId
        );
      } else if (this.state.formData.mainOffice.value !== "*") {
        return (
          parseInt(this.state.formData.mainOffice.value, 10) ===
          office.mainOfficeId
        );
      } else {
        return this.state.formData.region.value !== "*"
          ? parseInt(this.state.formData.region.value, 10) === office.regionId
          : true;
      }
    });
  };

  filteredPolicies = () => {
    let policies = [...this.props.singlePlan.policies];
    if (this.state.formData.policy.value === "*") {
      return policies;
    } else {
      return policies.filter(
        policy => this.state.formData.policy.value === policy.policyType
      );
    }
  };

  render() {
    return (
      <>
        <Row className="first-section p-3 px-sm-5 py-sm-5">
          <Col sm={6} lg={3} xl={3}>
            <FormGroup>
              <Input
                type="select"
                id="region"
                value={this.state.formData.region.value}
                invalid={
                  this.state.submitted && this.state.formData.region.invalid
                }
                onChange={event =>
                  this.inputHandler("region", event.target.value)
                }
              >
                {this.props.singlePlan.regions.length > 1 && (
                  <option value="*">Sve regije</option>
                )}
                {this.renderRegionOptions()}
              </Input>
            </FormGroup>
          </Col>
          <Col sm={6} lg={3} xl={3}>
            <FormGroup>
              <Input
                type="select"
                id="mainOffice"
                value={this.state.formData.mainOffice.value}
                invalid={
                  this.state.submitted && this.state.formData.mainOffice.invalid
                }
                onChange={event =>
                  this.inputHandler("mainOffice", event.target.value)
                }
              >
                {this.renderMainOfficeOptions()}
              </Input>
            </FormGroup>
          </Col>
          <Col sm={6} lg={3} xl={3}>
            <FormGroup>
              <Input
                type="select"
                id="office"
                value={this.state.formData.office.value}
                invalid={
                  this.state.submitted && this.state.formData.office.invalid
                }
                onChange={event =>
                  this.inputHandler("office", event.target.value)
                }
              >
                {this.renderOfficeOptions()}
              </Input>
            </FormGroup>
          </Col>
          <Col sm={6} lg={3} xl={3}>
            <FormGroup>
              <Input
                type="select"
                id="policy"
                value={this.state.formData.policy.value}
                invalid={
                  this.state.submitted && this.state.formData.policy.invalid
                }
                onChange={event =>
                  this.inputHandler("policy", event.target.value)
                }
              >
                {this.props.singlePlan.policies.length > 1 && (
                  <option value="*">Svi proizvodi</option>
                )}
                {this.renderPolicyOptions()}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <PlanDataCards
          planId={this.props.planId}
          redirectToOfficeDataTable={this.props.redirectToOfficeDataTable}
          offices={this.filteredOffices()}
          policies={this.filteredPolicies()}
        />
      </>
    );
  }
}

export default FilterForm;

import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { get } from "lodash-es";
import { client } from "../../config/apolloConfig";
import { loginFailure } from "../actions/authActions";
import {
  LOAD_BUSINESS_PLANS,
  loadBusinessPlansSuccess,
  loadBusinessPlansFailure,
  CREATE_BUSINESS_PLAN,
  createBusinessPlanFeedback,
  loadBusinessPlans
} from "../actions/businessActions";
import { generalError } from "../actions/errorActions";
import { BusinessPlansQuery } from "../../operations/queries/BusinessPlansQuery";
import { CreateBusinessPlanMutation } from "../../operations/mutations/CreateBusinessPlanMutation";
import {
  getErrorObject,
  isAuthTokenInvalid,
  isMaintenanceInProgress
} from "../../constants/errorCodes";
import {
  renderToaster,
  ERROR_TOASTER,
  SUCCESS_TOASTER
} from "../../constants/toaster";

/**
 * Loading Business Plans
 **/
function* loadPBusinessPlansWorker() {
  try {
    const apiResult = yield call(() =>
      client.query({
        query: BusinessPlansQuery
      })
    );

    const result = get(apiResult, "data", []);
    yield put(loadBusinessPlansSuccess(result));
  } catch (e) {
    yield put(loadBusinessPlansFailure());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* loadPBusinessPlansWatcher() {
  yield takeEvery(LOAD_BUSINESS_PLANS, loadPBusinessPlansWorker);
}

function* createBusinessPlanWorker(action) {
  try {
    const apiResult = yield call(() =>
      client.mutate({
        mutation: CreateBusinessPlanMutation,
        variables: action.payload
      })
    );

    const result = get(apiResult, "data.id", {});
    if (result) {
      renderToaster("Poslovni plan je uspješno dodan.", SUCCESS_TOASTER);
      yield all([put(createBusinessPlanFeedback()), put(loadBusinessPlans())]);
    }
  } catch (e) {
    yield put(createBusinessPlanFeedback());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* createBusinessPlanWatcher() {
  yield takeLatest(CREATE_BUSINESS_PLAN, createBusinessPlanWorker);
}

import { take, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { client } from "../../config/apolloConfig";
import {
  AUTH_ATTEMPT_LOGIN,
  AUTH_LOGOUT,
  AUTH_TOKEN_EXPIRED,
  AUTH_TOKEN_LOADED,
  AUTH_TOKEN_REFRESH,
  authTokenLoaded,
  loginFailure,
  loginSuccess,
  tokenRefreshSuccess
} from "../actions/authActions";
import { generalError } from "../actions/errorActions";
import LoginMutation from "../../operations/mutations/LoginMutation";
import MeQuery from "../../operations/queries/MeQuery";
import {
  removeLocalStorageToken,
  setLocalStorageToken
} from "../../constants/LocalStorageKeys";
import {
  getErrorObject,
  isMaintenanceInProgress
} from "../../constants/errorCodes";
import { get } from "lodash-es";

/**
 * Attempt Login Username Password
 **/
function* attemptLoginUsernamePasswordWorker(payload) {
  try {
    const loginResult = yield call(() =>
      client.mutate({
        mutation: LoginMutation,
        variables: { username: payload.username, password: payload.password }
      })
    );

    const token = get(loginResult, "data.login");

    if (token) {
      setLocalStorageToken(token, "attemptLoginUsernamePassword");
      yield put(authTokenLoaded({ authToken: token }));
    } else {
      yield put(loginFailure({ errorMessage: "Login Failed" }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    }
  }
}

export function* attemptLoginUsernamePasswordWatcher() {
  /*
    ***THIS IS A BLOCKING CALL***
    It means that watchCheckout will ignore any AUTH_ATTEMPT_LOGIN event until
    the current attemptLoginUsernamePasswordWorker completes, either by success or by Error
  */
  while (true) {
    const { payload } = yield take(AUTH_ATTEMPT_LOGIN);
    yield call(attemptLoginUsernamePasswordWorker, payload);
  }
}

/**
 * Attempt Loading Profile from Token
 **/
function* loadProfileFromTokenWorker() {
  try {
    const profileResult = yield call(() =>
      client.query({
        query: MeQuery
      })
    );

    const profile = get(profileResult, "data.myData", {});

    yield put(loginSuccess({ user: profile }));
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    }
  }
}

export function* loadProfileFromTokenWatcher() {
  yield takeEvery(AUTH_TOKEN_LOADED, loadProfileFromTokenWorker);
}

/**
 * Always update with the latest auth token
 **/
function* refreshTokenWorker(action) {
  try {
    const token = get(action, "payload");

    if (token) {
      setLocalStorageToken(token, "replaceOldAuthToken");
      yield put(tokenRefreshSuccess({ authToken: token }));
    }
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    }
  }
}

export function* refreshTokenWatcher() {
  yield takeEvery(AUTH_TOKEN_REFRESH, refreshTokenWorker);
}

/**
 * Remove user from local storage
 **/
export function* clearAuthorizedUserFromLocalStorage() {
  removeLocalStorageToken("clearAuthorizedUserFromLocalStorage");
  yield;
}

export function* authorizedUserLogoutWatcher() {
  yield takeLatest(AUTH_LOGOUT, clearAuthorizedUserFromLocalStorage);
}

export function* authorizedUserTokenExpiredWatcher() {
  yield takeEvery(AUTH_TOKEN_EXPIRED, clearAuthorizedUserFromLocalStorage);
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { get } from "lodash-es";
import AuthContainer from "../../containers/AuthContainer";
import { redirectToLogin } from "../../redux/actions/navigationActions";

const mapStateToProps = state => ({
  error: state.error
});

const mapDispatchToProps = dispatch => ({
  redirectToLogin: () => dispatch(redirectToLogin())
});

class ErrorPage extends Component {
  errorKey = get(this.props, "error.key", null);
  displayTitle = get(this.props, "error.title", "");
  displayMessage = get(this.props, "error.message", "");
  code = get(this.props, "error.code", "");

  componentDidMount() {
    if (!this.errorKey) {
      this.props.redirectToLogin();
    }
  }

  render() {
    return (
      <AuthContainer>
        {this.errorKey && (
          <Alert className="mt-4 mb-0" color="danger" role="alert" fade={false}>
            {this.displayTitle && (
              <h5 className="text-center mb-3">{this.displayTitle}</h5>
            )}
            {this.displayMessage && (
              <p className="mb-0">{this.displayMessage}</p>
            )}
          </Alert>
        )}
      </AuthContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPage);

import {
  LOAD_ALL_OFFICES_FOR_PLAN,
  LOAD_ALL_OFFICES_FOR_PLAN_SUCCESS,
  LOAD_ALL_OFFICES_FOR_PLAN_FAILURE,
  LOAD_OFFICE_POLICY_DATA,
  LOAD_OFFICE_POLICY_DATA_SUCCESS,
  LOAD_OFFICE_POLICY_DATA_FAILURE
} from "../actions/singlePlanActions";
import { isEmpty } from "lodash-es";

const initialState = {
  regions: [],
  mainOffices: [],
  offices: [],
  policies: [],
  officePolicyData: {},
  officesLoadingInProgress: false,
  officePolicyDataLoadingInProgress: false,
  isRegionsEmpty: true,
  isMainOfficesEmpty: true,
  isOfficesEmpty: true,
  isPoliciesEmpty: true
};

const singlePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL_OFFICES_FOR_PLAN:
      return { state, officesLoadingInProgress: true };
    case LOAD_ALL_OFFICES_FOR_PLAN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        officesLoadingInProgress: false,
        isRegionsEmpty: isEmpty(action.payload.regions),
        isMainOfficesEmpty: isEmpty(action.payload.mainOffices),
        isOfficesEmpty: isEmpty(action.payload.offices),
        isPoliciesEmpty: isEmpty(action.payload.policies)
      };
    case LOAD_ALL_OFFICES_FOR_PLAN_FAILURE:
      return { ...state, officesLoadingInProgress: false };
    case LOAD_OFFICE_POLICY_DATA:
      return { ...state, officePolicyDataLoadingInProgress: true };
    case LOAD_OFFICE_POLICY_DATA_SUCCESS:
      return { ...state, officePolicyDataLoadingInProgress: false };
    case LOAD_OFFICE_POLICY_DATA_FAILURE:
      return { ...state, officePolicyDataLoadingInProgress: false };
    default:
      return state;
  }
};

export default singlePlanReducer;

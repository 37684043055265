import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";
import { Container, Row, Col, Alert } from "reactstrap";
import FilterForm from "./FilterForm";
import { loadAllOfficesForPlan } from "../../redux/actions/singlePlanActions";
import { redirectToOfficeDataTable } from "../../redux/actions/navigationActions";
import { Spinner } from "../../containers/Spinner";

const mapStateToProps = state => ({
  business: state.business,
  singlePlan: state.singlePlan
});

const mapDispatchToProps = dispatch => ({
  loadAllOfficesForPlan: planId => dispatch(loadAllOfficesForPlan(planId)),
  redirectToOfficeDataTable: (planId, officeId, policyType) =>
    dispatch(redirectToOfficeDataTable(planId, officeId, policyType))
});

class BusinessPlan extends Component {
  planId = this.props.match.params.id;

  componentDidMount() {
    if (!get(this.props, "singlePlan.officesLoadingInProgress", false)) {
      this.props.loadAllOfficesForPlan(this.planId);
    }
  }

  render() {
    const singlePlanData = get(this.props, "singlePlan", {});

    if (get(this.props, "singlePlan.officesLoadingInProgress", false)) {
      return <Spinner className="spinner-positioning" fontSize="42px" />;
    } else {
      if (get(this.props, "singlePlan.isRegionsEmpty", true)) {
        return (
          <Container fluid>
            <Row className="no-gutters">
              <Col>
                <Alert color="warning">
                  <h5 className="text-center mb-0">
                    Nema ureda za odabrani plan
                  </h5>
                </Alert>
              </Col>
            </Row>
          </Container>
        );
      } else {
        return (
          <FilterForm
            planId={this.planId}
            redirectToOfficeDataTable={this.props.redirectToOfficeDataTable}
            singlePlan={singlePlanData}
          />
        );
      }
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPlan);

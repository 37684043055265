import {
  REFRESH_CONTRACT_SUCCESS,
  UPDATE_LANGUAGE_SELECTION,
  UPDATE_BANK_SELECTOR_SELECTION,
  UPDATE_AMOUNT_SELECTED,
  UPDATE_PAYMENT_SELECTED,
  UPDATE_CONTACT_SELECTED,
  UPDATE_WAY_OF_CONTACT_FIELD
} from "../actions/onlineBankomatActions";
import { get } from "lodash-es";

const initialState = {
  atmAvailable: ["HPB", "PBZ"],
  amountAvailable: {
    HPB: ["100", "200", "500", "1000", "2000", "3000"],
    PBZ: ["100", "200", "500", "1000"]
  }, // This tepends on the bank
  paymentAvailable: [/* "CREDITCARD",  */ "BTC", "ETH" /* , "PHONE" */],
  wayOfContactAvailable: ["SMS", "EMAIL"],

  atmEnabled: ["HPB", "PBZ"],
  amountEnabled: {
    HPB: ["100", "200", "500", "1000" /* , "2000", "3000" */],
    PBZ: ["100", "200", "500", "1000"]
  }, // This tepends on the bank
  paymentEnabled: [/* "CREDITCARD", */ "BTC", "ETH" /* "PHONE" */],
  wayOfContactEnabled: ["SMS", "EMAIL"],

  atmSelected: "NONE",
  amountSelected: "NONE",
  paymentSelected: "NONE",
  wayOfContactSelected: "NONE",

  email: "",
  emailIsValid: false,
  phoneNumber: "",
  phoneNumberIsValid: false,

  languageAvailable: ["HRV", "ENG" /* , "ITA", "DEU", "SLO" */],
  languageEnabled: ["HRV", "ENG"],
  languageSelected: "HRV"
};

const onlineBankomatReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_CONTRACT_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_LANGUAGE_SELECTION:
      const languageEnabled = get(state, "languageEnabled", []);
      const languageSelected = get(action.payload, "languageSelected", "HRV");
      if (
        !languageEnabled.includes(languageSelected) ||
        languageSelected === get(state, "languageSelected")
      ) {
        action.payload.languageSelected = "HRV";
      }
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_WAY_OF_CONTACT_FIELD:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_BANK_SELECTOR_SELECTION:
      const atmEnabled = get(state, "atmEnabled", []);
      const atmSelected = get(action.payload, "atmSelected", "NONE");
      if (
        !atmEnabled.includes(atmSelected) ||
        atmSelected === get(state, "atmSelected")
      ) {
        action.payload.atmSelected = "NONE";
      }
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_AMOUNT_SELECTED:
      const amountEnabled = get(
        state,
        `amountEnabled[${get(state, "atmSelected", "NONE")}]`,
        []
      );
      const amountSelected = get(action.payload, "amountSelected", "NONE");
      if (
        !amountEnabled.includes(amountSelected) ||
        amountSelected === get(state, "amountSelected")
      ) {
        action.payload.amountSelected = "NONE";
      }
      if (get(state, "atmSelected") === "NONE") {
        action.payload.amountSelected = "NONE";
      }
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_PAYMENT_SELECTED:
      const paymentEnabled = get(state, "paymentEnabled", []);
      const paymentSelected = get(action.payload, "paymentSelected", "NONE");
      if (
        !paymentEnabled.includes(paymentSelected) ||
        paymentSelected === get(state, "paymentSelected")
      ) {
        action.payload.paymentSelected = "NONE";
      }
      if (
        get(state, "amountSelected") === "NONE" ||
        get(state, "atmSelected") === "NONE"
      ) {
        action.payload.paymentSelected = "NONE";
      }
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_CONTACT_SELECTED:
      const wayOfContactEnabled = get(state, "wayOfContactEnabled", []);
      const wayOfContactSelected = get(
        action.payload,
        "wayOfContactSelected",
        "NONE"
      );
      if (
        !wayOfContactEnabled.includes(wayOfContactSelected) ||
        wayOfContactSelected === get(state, "wayOfContactSelected")
      ) {
        action.payload.wayOfContactSelected = "NONE";
      }
      if (
        get(state, "paymentSelected") === "NONE" ||
        get(state, "amountSelected") === "NONE" ||
        get(state, "atmSelected") === "NONE"
      ) {
        action.payload.wayOfContactSelected = "NONE";
      }
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default onlineBankomatReducer;

import { Component } from "react";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  router: state.router
});

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.router.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default connect(mapStateToProps)(ScrollToTop);

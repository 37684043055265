import {
  LOAD_BUSINESS_PLANS,
  LOAD_BUSINESS_PLANS_SUCCESS,
  LOAD_BUSINESS_PLANS_FAILURE,
  CREATE_BUSINESS_PLAN,
  CREATE_BUSINESS_PLAN_FEEDBACK
} from "../actions/businessActions";
import { isEmpty } from "lodash-es";

const initialState = {
  businessPlans: [],
  loadingInProgress: false,
  isEmpty: true,
  creatingBusinessPlan: false
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUSINESS_PLANS:
      return { ...state, loadingInProgress: true };
    case LOAD_BUSINESS_PLANS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingInProgress: false,
        isEmpty: isEmpty(action.payload)
      };
    case LOAD_BUSINESS_PLANS_FAILURE:
      return { ...state, loadingInProgress: false };
    case CREATE_BUSINESS_PLAN:
      return { ...state, creatingBusinessPlan: true };
    case CREATE_BUSINESS_PLAN_FEEDBACK:
      return { ...state, creatingBusinessPlan: false };

    default:
      return state;
  }
};

export default businessReducer;

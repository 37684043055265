import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_TO_DASHBOARD_PAGE } from "../../constants/routes";

export const Footer = () => {
  return (
    <>
      <span>
        <Link to={ROUTE_TO_DASHBOARD_PAGE}>HOK Osiguranje</Link> &copy;{" "}
        {new Date().getFullYear()}
      </span>
    </>
  );
};

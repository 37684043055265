import { toast } from "react-toastify";

export const DEFAULT_TOASTER = toast.TYPE.DEFAULT;
export const INFO_TOASTER = toast.TYPE.INFO;
export const WARNING_TOASTER = toast.TYPE.WARNING;
export const SUCCESS_TOASTER = toast.TYPE.SUCCESS;
export const ERROR_TOASTER = toast.TYPE.ERROR;

const options = {
  hideProgressBar: true,
  draggable: false
};

export const renderToaster = (errorMessage, type) => {
  toast(errorMessage, { type, ...options });
};

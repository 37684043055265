import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { apolloReducer } from "apollo-cache-redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import businessReducer from "./businessReducer";
import usersReducer from "./usersReducer";
import singlePlanReducer from "./singlePlanReducer";
import onlineBankomatReducer from "./onlineBankomatReducer";

export default history =>
  combineReducers({
    apollo: apolloReducer,
    authentication: authReducer,
    business: businessReducer,
    users: usersReducer,
    singlePlan: singlePlanReducer,
    error: errorReducer,
    onlineBankomat: onlineBankomatReducer,
    router: connectRouter(history)
  });

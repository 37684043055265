import React from "react";

export const Spinner = ({
  fontSize = "18px",
  className = "",
  loaderClassName = ""
}) => {
  className = className ? "text-center " + className : "text-center";
  loaderClassName = loaderClassName ? loaderClassName + " " : "";

  return (
    <div className={className}>
      <i
        className={loaderClassName + "fa fa-spinner fa-spin"}
        style={{ fontSize: fontSize }}
      />
    </div>
  );
};

import React, { Component } from "react";
import { Container, Row, Col, Badge } from "reactstrap";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { formatDate } from "../../constants/dateFormatting";

class BusinessPlans extends Component {
  formatStatusCell = data => {
    let badge = { color: "", text: "" };
    if (!data.approved && !data.locked && !data.loadedInWh) {
      badge = { color: "success", text: "aktivno" };
    } else if (data.loadedInWh) {
      badge = { color: "dark", text: "učitano u DW" };
    } else if (data.locked) {
      badge = { color: "danger", text: "zaključano" };
    } else if (data.approved) {
      badge = { color: "secondary", text: "odobreno" };
    }

    return (
      <h5
        style={{
          float: "left",
          marginRight: "5px",
          marginBottom: 0,
          lineHeight: "33px"
        }}
      >
        <Badge color={badge.color}>{badge.text.toUpperCase()}</Badge>
      </h5>
    );
  };

  NAME_COLUMN = {
    id: "name",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Ime Plana",
    minWidth: 200,
    sortable: false,
    Cell: rowInfo => <div>{rowInfo.original.name}</div>
  };

  COMMENT_COLUMN = {
    id: "comment",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Opis Plana",
    minWidth: 200,
    sortable: false,
    Cell: rowInfo => <div>{rowInfo.original.comment}</div>
  };

  TYPE_COLUMN = {
    id: "type",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Tip Plana",
    minWidth: 120,
    sortable: false,
    Cell: rowInfo => {
      let text = "Nije definirano";
      if (rowInfo.original.type === "year") {
        text = "Godišnji plan";
      }
      if (rowInfo.original.type === "quartal") {
        text = "Kvartalni plan";
      }
      if (rowInfo.original.type === "month") {
        text = "Mjesečni plan";
      }
      return <div>{text}</div>;
    }
  };

  START_DATE_COLUMN = {
    id: "startDate",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Početni datum",
    minWidth: 120,
    sortable: false,
    Cell: rowInfo => <div>{formatDate(rowInfo.original.startDate)}</div>
  };

  END_DATE_COLUMN = {
    id: "endDate",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Završni datum",
    minWidth: 120,
    sortable: false,
    Cell: rowInfo => <div>{formatDate(rowInfo.original.endDate)}</div>
  };

  STATUS_COLUMN = {
    id: "status",
    headerClassName: "react-table-headers",
    className: "react-table-cells",
    Header: "Status",
    minWidth: 120,
    sortable: false,
    Cell: rowInfo => this.formatStatusCell(rowInfo.original)
  };

  fetchColumns = () => {
    return [
      this.NAME_COLUMN,
      this.COMMENT_COLUMN,
      this.TYPE_COLUMN,
      this.START_DATE_COLUMN,
      this.END_DATE_COLUMN,
      this.STATUS_COLUMN
    ];
  };

  render() {
    const businessPlans = this.props.business.businessPlans;
    return (
      <Container fluid>
        <Row>
          <Col>
            <h4>Planovi prodaje</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReactTable
              id="poslovniPlanovi"
              ref="poslovniPlanovi"
              className="-striped -highlight business-plans"
              data={businessPlans}
              columns={this.fetchColumns()}
              loading={this.props.business.loadingInProgress}
              resizable={true}
              pageSize={businessPlans.length}
              showPagination={false}
              loadingText="Učitavanje u tijeku..."
              noDataText="Nema podataka"
              getTrProps={(state, rowInfo, column, instance) => ({
                onClick: () => this.props.redirectToPlan(rowInfo.original.id)
              })}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BusinessPlans;

import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "lodash-es";
import { client } from "../../config/apolloConfig";
import { loginFailure } from "../actions/authActions";
import {
  LOAD_ALL_OFFICES_FOR_PLAN,
  loadAllOfficesForPlanSuccess,
  loadAllOfficesForPlanFailure
} from "../actions/singlePlanActions";
import { generalError } from "../actions/errorActions";
import GetAllSinglePlanDataQuery from "../../operations/queries/GetAllSinglePlanDataQuery";
import {
  getErrorObject,
  isAuthTokenInvalid,
  isMaintenanceInProgress
} from "../../constants/errorCodes";
import { renderToaster, ERROR_TOASTER } from "../../constants/toaster";

/**
 * Loading All Offices For Single Plan
 **/
function* loadAllOfficesForSinglePlanWorker(action) {
  try {
    const apiResult = yield call(() =>
      client.query({
        query: GetAllSinglePlanDataQuery,
        variables: { businessPlanId: parseInt(action.payload, 10) }
      })
    );

    const result = get(apiResult, "data", []);
    yield put(loadAllOfficesForPlanSuccess(result));
  } catch (e) {
    yield put(loadAllOfficesForPlanFailure());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* loadAllOfficesForSinglePlanWatcher() {
  yield takeEvery(LOAD_ALL_OFFICES_FOR_PLAN, loadAllOfficesForSinglePlanWorker);
}

import { all, call, put, takeEvery } from "redux-saga/effects";
import { get } from "lodash-es";
import { client } from "../../config/apolloConfig";
import { loginFailure } from "../actions/authActions";
import {
  getErrorObject,
  isAuthTokenInvalid,
  isMaintenanceInProgress
} from "../../constants/errorCodes";
import {
  renderToaster,
  ERROR_TOASTER,
  SUCCESS_TOASTER
} from "../../constants/toaster";
import {
  LOAD_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  LOAD_OFFICES,
  LOAD_REGIONS,
  loadUsersSuccess,
  loadUsersFailure,
  loadUsers,
  createUserFeedback,
  updateUserFeedback,
  deleteUserSuccess,
  loadMainOfficesSucess,
  loadRegionsSucess
} from "../actions/usersActions";
import { generalError } from "../actions/errorActions";
import { UsersQuery } from "../../operations/queries/GetUsersQuery";
import { CreateUserMutation } from "../../operations/mutations/CreateUserMutation";
import { DeleteUserMutation } from "../../operations/mutations/DeleteUserMutation";
import { UpdateUserMutation } from "../../operations/mutations/UpdateUserMutation";
import { GetMainOfficesQuery } from "../../operations/queries/GetMainOfficesQuery";
import { GetRegionsQuery } from "../../operations/queries/GetRegionsQuery";

/**
 * Load Users
 **/
function* loadUsersWorker() {
  try {
    const apiResult = yield call(() =>
      client.query({
        query: UsersQuery
      })
    );

    const result = get(apiResult, "data", []);

    yield put(loadUsersSuccess(result));
  } catch (e) {
    yield put(loadUsersFailure());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* loadUsersWatcher() {
  yield takeEvery(LOAD_USERS, loadUsersWorker);
}

/**
 *  Load offices
 **/
function* loadMainOffices() {
  try {
    const apiResult = yield call(() =>
      client.query({ query: GetMainOfficesQuery })
    );

    const result = get(apiResult, "data.getMainOffices", []).map(row => ({
      value: "MO-" + row.mainOfficeId,
      label: row.mainOfficeName
    }));

    yield put(loadMainOfficesSucess(result));
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* loadMainOfficesWatcher() {
  yield takeEvery(LOAD_OFFICES, loadMainOffices);
}

/**
 *  Load regions
 **/
function* loadRegions() {
  try {
    const apiResult = yield call(() =>
      client.query({ query: GetRegionsQuery })
    );

    const result = get(apiResult, "data.getRegions", []).map(row => ({
      value: "RE-" + row.regionId,
      label: row.regionName
    }));

    yield put(loadRegionsSucess(result));
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* loadRegionsWatcher() {
  yield takeEvery(LOAD_REGIONS, loadRegions);
}

/**
 * Create user
 **/
function* createUserWorker(action) {
  try {
    const apiResult = yield call(() =>
      client.mutate({
        mutation: CreateUserMutation,
        variables: action.payload
      })
    );

    const result = get(apiResult, "data.createUser", {});
    if (result.id) {
      renderToaster(`Korisnik je uspješno napravljen.`, SUCCESS_TOASTER);
      yield all([put(createUserFeedback()), put(loadUsers())]);
    }
  } catch (e) {
    yield put(createUserFeedback());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* createUserWatcher() {
  yield takeEvery(CREATE_USER, createUserWorker);
}

/**
 * Update user
 **/
function* updateUserWorker(action) {
  try {
    const apiResult = yield call(() =>
      client.mutate({
        mutation: UpdateUserMutation,
        variables: action.payload
      })
    );

    const result = get(apiResult, "data.updateUser", {});
    if (result) {
      renderToaster(`Korisnik je uspješno ažuriran.`, SUCCESS_TOASTER);
      yield all([put(updateUserFeedback()), put(loadUsers())]);
    }
  } catch (e) {
    yield put(updateUserFeedback());
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* updateUserWatcher() {
  yield takeEvery(UPDATE_USER, updateUserWorker);
}

/**
 * Delete user
 **/
function* deleteUserWorker(action) {
  try {
    const apiResult = yield call(() =>
      client.mutate({
        mutation: DeleteUserMutation,
        variables: action.payload
      })
    );

    const result = get(apiResult, "data.deleteUser", {});
    renderToaster(`Korisnik je uspješno izbrisan.`, SUCCESS_TOASTER);
    yield put(deleteUserSuccess(result.id));
  } catch (e) {
    const errorObject = getErrorObject(e);
    if (isAuthTokenInvalid(errorObject)) {
      yield put(loginFailure({ errorMessage: errorObject.message }));
    } else if (isMaintenanceInProgress(errorObject)) {
      yield put(generalError({ ...errorObject }));
    } else {
      renderToaster(errorObject.message, ERROR_TOASTER);
    }
  }
}

export function* deleteUserWatcher() {
  yield takeEvery(DELETE_USER, deleteUserWorker);
}

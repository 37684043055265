import React, { Component } from "react";
import { connect } from "react-redux";
import { loadBusinessPlans } from "../../redux/actions/businessActions";
import BusinessPlans from "../Dashboard/BusinessPlans";
import { redirectToPlan } from "../../redux/actions/navigationActions";

const mapStateToProps = state => ({
  user: state.authentication.user,
  business: state.business
});

const mapDispatchToProps = dispatch => ({
  loadBusinessPlans: () => dispatch(loadBusinessPlans()),
  redirectToPlan: planId => dispatch(redirectToPlan(planId))
});

class BusinessPlansPreview extends Component {
  componentDidMount() {
    this.props.loadBusinessPlans();
  }

  render() {
    return (
      <BusinessPlans
        business={this.props.business}
        redirectToPlan={this.props.redirectToPlan}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPlansPreview);

export const LOAD_ALL_OFFICES_FOR_PLAN = "LOAD_ALL_OFFICES_FOR_PLAN";
export const loadAllOfficesForPlan = planId => ({
  type: LOAD_ALL_OFFICES_FOR_PLAN,
  payload: planId
});

export const LOAD_ALL_OFFICES_FOR_PLAN_SUCCESS =
  "LOAD_ALL_OFFICES_FOR_PLAN_SUCCESS";
export const loadAllOfficesForPlanSuccess = allOfficesData => ({
  type: LOAD_ALL_OFFICES_FOR_PLAN_SUCCESS,
  payload: allOfficesData
});

export const LOAD_ALL_OFFICES_FOR_PLAN_FAILURE =
  "LOAD_ALL_OFFICES_FOR_PLAN_FAILURE";
export const loadAllOfficesForPlanFailure = () => ({
  type: LOAD_ALL_OFFICES_FOR_PLAN_FAILURE
});

export const LOAD_OFFICE_POLICY_DATA = "LOAD_OFFICE_POLICY_DATA";
export const loadOfficePolicyData = payload => ({
  type: LOAD_OFFICE_POLICY_DATA,
  payload: payload
});

export const LOAD_OFFICE_POLICY_DATA_SUCCESS =
  "LOAD_OFFICE_POLICY_DATA_SUCCESS";
export const loadOfficePolicyDataSuccess = officePolicyData => ({
  type: LOAD_OFFICE_POLICY_DATA_SUCCESS,
  payload: officePolicyData
});

export const LOAD_OFFICE_POLICY_DATA_FAILURE =
  "LOAD_OFFICE_POLICY_DATA_FAILURE";
export const loadOfficePolicyDataFailure = () => ({
  type: LOAD_OFFICE_POLICY_DATA_FAILURE
});

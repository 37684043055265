import gql from "graphql-tag";

export const CreateBusinessPlanMutation = gql`
  mutation CreateBusinessPlan(
    $name: String!
    $comment: String!
    $selectedDate: Date!
    $type: String!
  ) {
    createBusinessPlan(
      name: $name
      comment: $comment
      selectedDate: $selectedDate
      type: $type
    ) {
      id
    }
  }
`;

export default CreateBusinessPlanMutation;

export const LOAD_BUSINESS_PLANS = "LOAD_BUSINESS_PLANS";
export const loadBusinessPlans = () => ({
  type: LOAD_BUSINESS_PLANS
});

export const LOAD_BUSINESS_PLANS_SUCCESS = "LOAD_BUSINESS_PLANS_SUCCESS";
export const loadBusinessPlansSuccess = businessPlans => ({
  type: LOAD_BUSINESS_PLANS_SUCCESS,
  payload: businessPlans
});

export const LOAD_BUSINESS_PLANS_FAILURE = "LOAD_BUSINESS_PLANS_FAILURE";
export const loadBusinessPlansFailure = businessPlans => ({
  type: LOAD_BUSINESS_PLANS_FAILURE,
  payload: businessPlans
});

export const CREATE_BUSINESS_PLAN = "CREATE_BUSINESS_PLAN";
export const createBusinessPlan = businessPlanObject => ({
  type: CREATE_BUSINESS_PLAN,
  payload: businessPlanObject
});

export const CREATE_BUSINESS_PLAN_FEEDBACK = "CREATE_BUSINESS_PLAN_FEEDBACK";
export const createBusinessPlanFeedback = () => ({
  type: CREATE_BUSINESS_PLAN_FEEDBACK
});

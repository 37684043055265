import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";

import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Button, FormGroup, FormFeedback, Input } from "reactstrap";

import LanguageSelector from "./components/LanguageSelector";
import AmountSelector from "./components/AmountSelector";
import BankSelector from "./components/BankSelector";
import PaymentSelector from "./components/PaymentSelector";
import ContactSelector from "./components/ContactSelector";

import {
  updateWayOfContactField,
  getAvailableOptions,
  createNewTransaction
} from "../redux/actions/onlineBankomatActions";

const checkIfMailIsValid = string => {
  const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return string.match(EMAIL_PATTERN);
};
const checkIfPhoneIsValid = string => {
  const PHONE_PATTERN = /^(\+|00)(\d{1,3})(\d{2,3}?)(\d{6,7}?)$/;
  return string.match(PHONE_PATTERN);
};

const mapStateToProps = state => ({
  onlineBankomat: state.onlineBankomat
});

const mapDispatchToProps = dispatch => ({
  getAvailableOptions: () => dispatch(getAvailableOptions()),
  createNewTransaction: () => dispatch(createNewTransaction()),
  updateWayOfContactField: (field, e) =>
    dispatch(updateWayOfContactField(field, e))
});

class OnlineBankomat extends Component {
  componentDidMount() {
    // Dispatch action to get current available options
    // this.props.getAvailableOptions();
  }

  fieldChangeEventHandler(field) {
    return e => {
      if (field === "EMAIL") {
        this.props.updateWayOfContactField(field, e);
      }
      if (field === "SMS") {
        this.props.updateWayOfContactField(field, e);
      }
    };
  }

  render() {
    const languageSelected =
      get(this.props, "onlineBankomat.languageSelected") === "NONE"
        ? "HRV"
        : get(this.props, "onlineBankomat.languageSelected", "HRV");
    const atmSelected = get(this.props, "onlineBankomat.atmSelected");
    const amountSelected = get(this.props, "onlineBankomat.amountSelected");
    const paymentSelected = get(this.props, "onlineBankomat.paymentSelected");
    const wayOfContactSelected = get(
      this.props,
      "onlineBankomat.wayOfContactSelected"
    );

    const email = get(this.props, "onlineBankomat.email");
    const emailIsValid = checkIfMailIsValid(email);

    const phoneNumber = get(this.props, "onlineBankomat.phoneNumber");
    const phoneNumberIsValid = checkIfPhoneIsValid(phoneNumber);

    let LANGUAGE_CHOOSE_LANGUAGE = "";
    let LANGUAGE_CHOOSE_ATM = "";
    let LANGUAGE_CHOOSE_AMOUNT = "";
    let LANGUAGE_CHOOSE_PAYMENT_TYPE = "";
    let LANGUAGE_CHOOSE_CONTACT_TYPE = "";
    let LANGUAGE_BACK = "";
    let LANGUAGE_REQUEST_THE_CODE = "";
    let LANGUAGE_EMAIL_IS_INVALID = "";
    let LANGUAGE_PHONE_NUMBER_IS_INVALID = "";
    if (languageSelected === "HRV") {
      LANGUAGE_CHOOSE_LANGUAGE = "Izaberi jezik:";
      LANGUAGE_CHOOSE_ATM = "Izaberi bankomat:";
      LANGUAGE_CHOOSE_AMOUNT = "Izaberi iznos:";
      LANGUAGE_CHOOSE_PAYMENT_TYPE = "Izaberi način plaćanja:";
      LANGUAGE_CHOOSE_CONTACT_TYPE = "Izaberi kako da dobiješ kod:";
      LANGUAGE_BACK = "Povratak";
      LANGUAGE_REQUEST_THE_CODE = "Zatraži kod";
      LANGUAGE_EMAIL_IS_INVALID =
        "Email nije validan, molimo unesite validnu email adresu.";
      LANGUAGE_PHONE_NUMBER_IS_INVALID =
        "Broj telefona nije validan, molimo unesite validni broj telefona.";
    }
    if (languageSelected === "ENG") {
      LANGUAGE_CHOOSE_LANGUAGE = "Choose language:";
      LANGUAGE_CHOOSE_ATM = "Choose ATM:";
      LANGUAGE_CHOOSE_AMOUNT = "Choose amount:";
      LANGUAGE_CHOOSE_PAYMENT_TYPE = "Choose way of payment:";
      LANGUAGE_CHOOSE_CONTACT_TYPE =
        "Choose how do you want to recieve the code:";
      LANGUAGE_BACK = "Back";
      LANGUAGE_REQUEST_THE_CODE = "Request the code";
      LANGUAGE_EMAIL_IS_INVALID =
        "Email address is invalid, please insert valid email address.";
      LANGUAGE_PHONE_NUMBER_IS_INVALID =
        "Phone number is invalid, please insert a valid phone number.";
    }

    return (
      <div
        className="App"
        style={{ textAlign: "center", WebkitFilter: "blur(0px) saturate(1)" }}
      >
        <Container>
          {/*       <Row>
            <Col>
              <br />
              <p>Izaberi vrstu transakcije:</p>
              <BankSelector />
            </Col>
          </Row> */}
          <Row>
            <Col>
              <br />
              <p>{LANGUAGE_CHOOSE_LANGUAGE}</p>
              <LanguageSelector />
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <p>{LANGUAGE_CHOOSE_ATM}</p>
              <BankSelector />
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <p>{LANGUAGE_CHOOSE_AMOUNT}</p>
              <AmountSelector />
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <p>{LANGUAGE_CHOOSE_PAYMENT_TYPE}</p>
              <PaymentSelector />
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <p>{LANGUAGE_CHOOSE_CONTACT_TYPE}</p>
              <ContactSelector />
            </Col>
          </Row>
          <Row>
            <Col>
              <li>
                {wayOfContactSelected === "EMAIL" && (
                  <FormGroup>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Unesi email"
                      valid={!!emailIsValid}
                      invalid={!emailIsValid}
                      value={email !== "" ? email : undefined}
                      onChange={this.fieldChangeEventHandler("EMAIL")}
                    />
                    <FormFeedback tooltip>
                      {LANGUAGE_EMAIL_IS_INVALID}
                    </FormFeedback>
                  </FormGroup>
                )}
                {wayOfContactSelected === "SMS" && (
                  <FormGroup>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Unesi broj telefona (+385991231234)"
                      valid={!!phoneNumberIsValid}
                      invalid={!phoneNumberIsValid}
                      value={phoneNumber !== "" ? phoneNumber : undefined}
                      onChange={this.fieldChangeEventHandler("SMS")}
                    />
                    <FormFeedback tooltip>
                      {LANGUAGE_PHONE_NUMBER_IS_INVALID}
                    </FormFeedback>
                  </FormGroup>
                )}
              </li>
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
            </Col>
          </Row>
          <Row>
            <Col sm="6" md={{ size: 3, offset: 3 }}>
              <br />
              <Link to="/">
                <Button color="info" size="lg" style={{}}>
                  &lt; {LANGUAGE_BACK}
                </Button>
              </Link>
              <br />
              <br />
            </Col>
            <Col sm="6" md={{ size: 3, offset: 0 }}>
              <br />
              <Link to="/reservation/">
                <Button
                  disabled={
                    atmSelected !== "NONE" &&
                    amountSelected !== "NONE" &&
                    paymentSelected !== "NONE" &&
                    wayOfContactSelected !== "NONE" &&
                    ((wayOfContactSelected === "EMAIL" && emailIsValid) ||
                      (wayOfContactSelected === "SMS" && phoneNumberIsValid))
                      ? false
                      : true
                  }
                  color="success"
                  size="lg"
                  style={{}}
                >
                  {LANGUAGE_REQUEST_THE_CODE}
                </Button>
              </Link>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineBankomat);

import React, { Component } from "react";

class LandingPage extends Component {
  render() {
    const src = "https://onlinebankomat.com/site/index.html";

    return (
      <>
        <iframe
          title={"Display Box"}
          style={{ border: "none", display: "block" }}
          src={src}
          height={"100%"}
          width={"100%"}
        />
      </>
    );
  }
}

export default LandingPage;

import { all } from "redux-saga/effects";
import {
  redirectToLoginPageWatcher,
  redirectToErrorPageWatcher,
  loginSuccessWatcher,
  logoutWatcher,
  loginFailureWatcher,
  redirectToDashboardPageWatcher,
  redirectToPlanPageWatcher,
  redirectToOfficeDataTablePageWatcher
} from "./navigationSagas";
import {
  attemptLoginUsernamePasswordWatcher,
  loadProfileFromTokenWatcher,
  refreshTokenWatcher,
  authorizedUserLogoutWatcher,
  authorizedUserTokenExpiredWatcher
} from "./authSagas";
import {
  loadPBusinessPlansWatcher,
  createBusinessPlanWatcher
} from "./businessSagas";
import {
  loadUsersWatcher,
  createUserWatcher,
  deleteUserWatcher,
  updateUserWatcher,
  loadMainOfficesWatcher,
  loadRegionsWatcher
} from "./usersSagas";
import { loadAllOfficesForSinglePlanWatcher } from "./singlePlanSagas";
import {
  createNewContractWatcher,
  refreshContractWatcher,
  reservationFinishedWatcher
} from "./onlineBankomatSagas";

export default function* rootSaga() {
  yield all([
    /** Authentication Sagas **/
    attemptLoginUsernamePasswordWatcher(),
    loadProfileFromTokenWatcher(),
    refreshTokenWatcher(),
    authorizedUserLogoutWatcher(),
    authorizedUserTokenExpiredWatcher(),
    /** Nagigation Sagas **/
    redirectToLoginPageWatcher(),
    redirectToErrorPageWatcher(),
    redirectToDashboardPageWatcher(),
    redirectToPlanPageWatcher(),
    redirectToOfficeDataTablePageWatcher(),
    loginSuccessWatcher(),
    loginFailureWatcher(),
    logoutWatcher(),
    /** Business Sagas **/
    loadPBusinessPlansWatcher(),
    createBusinessPlanWatcher(),
    /** Users Sagas **/
    loadUsersWatcher(),
    createUserWatcher(),
    updateUserWatcher(),
    deleteUserWatcher(),
    loadMainOfficesWatcher(),
    loadRegionsWatcher(),
    /** Single Plan Sagas **/
    loadAllOfficesForSinglePlanWatcher(),
    /** Online Bankomat Sagas **/
    createNewContractWatcher(),
    refreshContractWatcher(),
    reservationFinishedWatcher()
  ]);
}

import {
  AUTH_ATTEMPT_LOGIN,
  AUTH_TOKEN_EXPIRED,
  AUTH_TOKEN_LOADED,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  TOKEN_REFRESH_SUCCESS,
  CLEAR_ERROR_MESSAGE
} from "../actions/authActions";

import { removeLocalStorageToken } from "../../constants/LocalStorageKeys";

const initialState = {
  user: {
    username: "",
    displayName: "",
    role: "",
    firstName: "",
    lastName: "",
    email: "",
    updatedAt: "",
    createdAt: "",
    officeAssigned: []
  },
  loginInProgress: false,
  errorMessage: null,
  authToken: null,
  lastAuth: new Date(),
  passwordRecovery: {
    successMessage: null,
    errorMessage: null
  },
  passwordReset: {
    errorMessage: null
  }
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ATTEMPT_LOGIN:
      return { ...state, loginInProgress: true };

    case AUTH_TOKEN_EXPIRED:
      removeLocalStorageToken("Auth Reducer AUTH_TOKEN_EXPIRED");
      return { ...state, ...action.payload, authToken: null };

    case AUTH_TOKEN_LOADED:
      return { ...state, ...action.payload };

    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        errorMessage: null,
        loginInProgress: false
      };

    case LOGIN_FAILURE:
      removeLocalStorageToken("Auth Reducer LOGIN_FAILURE");
      return {
        ...state,
        ...action.payload,
        authToken: null,
        loginInProgress: false
      };

    case TOKEN_REFRESH_SUCCESS:
      return { ...state, ...action.payload };

    case CLEAR_ERROR_MESSAGE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default authReducer;

export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";
export const redirectToLogin = () => {
  return {
    type: REDIRECT_TO_LOGIN
  };
};

export const REDIRECT_TO_DASHBOARD = "REDIRECT_TO_DASHBOARD";
export const redirectToDashboard = () => {
  return {
    type: REDIRECT_TO_DASHBOARD
  };
};

export const REDIRECT_TO_ERROR_PAGE = "REDIRECT_TO_ERROR_PAGE";
export const redirectToErrorPage = () => {
  return {
    type: REDIRECT_TO_ERROR_PAGE
  };
};

export const REDIRECT_TO_PLAN_PAGE = "REDIRECT_TO_PLAN_PAGE";
export const redirectToPlan = planId => {
  return {
    type: REDIRECT_TO_PLAN_PAGE,
    payload: planId
  };
};

export const REDIRECT_TO_OFFICE_DATA_TABLE_PAGE =
  "REDIRECT_TO_OFFICE_DATA_TABLE_PAGE";
export const redirectToOfficeDataTable = (planId, officeId, policyType) => {
  return {
    type: REDIRECT_TO_OFFICE_DATA_TABLE_PAGE,
    payload: { planId, officeId, policyType }
  };
};

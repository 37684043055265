import gql from "graphql-tag";

export const BusinessPlansQuery = gql`
  query businessPlans {
    businessPlans: getBusinessPlans {
      id
      name
      comment
      startDate
      endDate
      selectedDate
      type
      approved
      locked
      loadedInWh
      updatedAt
      createdAt
      actualPlanRange {
        range
        months
      }
      exPlanRangeToShowDataFrom {
        range
        months
      }
    }
  }
`;

export default BusinessPlansQuery;

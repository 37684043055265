import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";
import { updateLanguageSelection } from "../../redux/actions/onlineBankomatActions";

import { Col, Card, CardImg, CardBody, CardTitle } from "reactstrap";

import HRVimage from "./../../img/HRV.png";
import ENGimage from "./../../img/ENG.png";
import GERimage from "./../../img/GER.png";
import ITAimage from "./../../img/ITA.png";
import SLOimage from "./../../img/SLO.png";

const mapStateToProps = state => ({
  onlineBankomat: state.onlineBankomat
});

const mapDispatchToProps = dispatch => ({
  updateBankSelectorSelection: language =>
    dispatch(updateLanguageSelection(language))
});

class LanguageSelector extends Component {
  _selectDifferentLanguage = language => () => {
    this.props.updateBankSelectorSelection(language);
  };

  render() {
    const languageAvailable = get(
      this.props,
      "onlineBankomat.languageAvailable"
    );
    const languageSelected = get(this.props, "onlineBankomat.languageSelected");
    const languageEnabled = get(
      this.props,
      "onlineBankomat.languageEnabled",
      []
    );

    const cardClassNameFunction = language => {
      if (languageEnabled.includes(language)) {
        if (languageSelected === language) {
          return "selected";
        } else {
          return "not-selected";
        }
      }
      return "disabled";
    };

    return (
      <div className="row bankSelector">
        <Col>
          <ul>
            {languageAvailable.includes("HRV") && (
              <li>
                <Card
                  className={cardClassNameFunction("HRV")}
                  onClick={this._selectDifferentLanguage("HRV")}
                  style={{ cursor: "pointer", width: "64px" }}
                >
                  <CardImg top width="100%" src={HRVimage} alt="HRV" />
                </Card>
              </li>
            )}
            {languageAvailable.includes("ENG") && (
              <li>
                <Card
                  className={cardClassNameFunction("ENG")}
                  onClick={this._selectDifferentLanguage("ENG")}
                  style={{ cursor: "pointer", width: "64px" }}
                >
                  <CardImg top width="100%" src={ENGimage} alt="ENG" />
                </Card>
              </li>
            )}
            {languageAvailable.includes("GER") && (
              <li>
                <Card
                  className={cardClassNameFunction("GER")}
                  onClick={this._selectDifferentLanguage("GER")}
                  style={{ cursor: "pointer", width: "64px" }}
                >
                  <CardImg top width="100%" src={GERimage} alt="GER" />
                </Card>
              </li>
            )}
            {languageAvailable.includes("ITA") && (
              <li>
                <Card
                  className={cardClassNameFunction("ITA")}
                  onClick={this._selectDifferentLanguage("ITA")}
                  style={{ cursor: "pointer", width: "64px" }}
                >
                  <CardImg top width="100%" src={ITAimage} alt="ITA" />
                </Card>
              </li>
            )}
            {languageAvailable.includes("SLO") && (
              <li>
                <Card
                  className={cardClassNameFunction("SLO")}
                  onClick={this._selectDifferentLanguage("SLO")}
                  style={{ cursor: "pointer", width: "64px" }}
                >
                  <CardImg top width="100%" src={SLOimage} alt="SLO" />
                </Card>
              </li>
            )}
          </ul>
        </Col>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelector);

const AUTH_TOKEN_KEY = "AUTH_TOKEN_KEY";

export const getLocalStorageToken = (location = "Unknown") => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  return token;
};

export const setLocalStorageToken = (token, location = "Unknown") => {
  return localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const removeLocalStorageToken = (location = "Unknown") => {
  return localStorage.removeItem(AUTH_TOKEN_KEY);
};

import gql from "graphql-tag";

export const MeQuery = gql`
  query me {
    myData: me {
      id
      username
      role
      email
      updatedAt
      createdAt
      firstName
      lastName
      firstName
      lastName
      avatar
      officeAssigned
      passwordExpires
      passwordExpired
    }
  }
`;

export default MeQuery;

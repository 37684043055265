import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Collapse,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";
import { get } from "lodash-es";

class PlanDataCards extends Component {
  state = {
    isOpen: {
      ...this.props.offices.map(office => {
        return { [office.officeId]: false };
      })
    }
  };

  toggle = officeId => {
    this.setState({
      isOpen: { ...this.state.isOpen, [officeId]: !this.state.isOpen[officeId] }
    });
  };

  render() {
    const policies = get(this.props, "policies", []);
    const offices = get(this.props, "offices", []);

    return (
      <Container fluid>
        {offices.map(office => (
          <Row className="no-gutters" key={office.officeId}>
            <Col>
              <Card className="plan-preview-card">
                <div onClick={() => this.toggle(office.officeId)}>
                  <CardHeader className="plan-preview-card-header px-4 px-sm-5">
                    <i
                      className={
                        "fa " +
                        (this.state.isOpen[office.officeId]
                          ? "fa-chevron-up"
                          : "fa-chevron-down") +
                        " fa-lg mr-3 mr-sm-5 plan-preview-toggle-icon"
                      }
                    />
                    <h5 className="mb-0">{`${office.regionName} / ${
                      office.mainOfficeName
                    } / ${office.officeName}`}</h5>
                  </CardHeader>
                </div>
                <Collapse isOpen={this.state.isOpen[office.officeId]}>
                  <CardBody className="plan-preview-card-body px-4 px-sm-5">
                    <Row>
                      {policies.map(policy => (
                        <Col key={policy.policyType} xs={12}>
                          <p>
                            <span
                              onClick={() =>
                                this.props.redirectToOfficeDataTable(
                                  this.props.planId,
                                  office.officeId,
                                  policy.policyType
                                )
                              }
                            >
                              {`${office.regionName} / ${
                                office.mainOfficeName
                              } / ${office.officeName} / ${policy.policyType}`}
                            </span>
                          </p>
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
          </Row>
        ))}
      </Container>
    );
  }
}

export default PlanDataCards;
